var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      staticClass: "mx-auto",
      attrs: {
        title: "Phone",
        "heading-color": "secondary",
        "btn-color": "secondary",
        icon: "mdi-phone",
        "hide-actions": "",
        "card-text-class": "pa-2"
      },
      on: { close: _vm.close }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between justify-space-around" },
        [
          _c(
            "v-slide-x-transition",
            [
              _c("web-phone", {
                attrs: {
                  contacts: _vm.contacts,
                  applicant: _vm.applicant,
                  order_id: _vm.order_id
                },
                on: { "show:info": _vm.showInfo }
              })
            ],
            1
          ),
          _c("v-slide-x-reverse-transition", [
            _vm.shouldShowInfo
              ? _c(
                  "div",
                  { staticStyle: { "max-width": "1060px" } },
                  [
                    _c(
                      _vm.info.component,
                      _vm._b(
                        {
                          tag: "component",
                          attrs: {
                            order_id: _vm.info.order_id,
                            addresses: _vm.info.items,
                            employers: _vm.info.items
                          }
                        },
                        "component",
                        _vm.componentParams[_vm.info.type],
                        false
                      )
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c("psi-confirm-dialog", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }