var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h3",
        { staticClass: "my-4 font-weight-regular" },
        [_vm._v(" Verify "), _c("v-spacer")],
        1
      ),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                { staticClass: "mt-5" },
                [
                  _c(
                    "v-icon",
                    { attrs: { large: "", color: "accent darken-2" } },
                    [_vm._v("mdi-account-check")]
                  )
                ],
                1
              ),
              _c(
                "v-list-item-title",
                {
                  staticClass:
                    "d-flex text-subtitle-1 align-center font-weight-regular"
                },
                [
                  _vm._v(" Identification "),
                  _c("v-spacer"),
                  _c("psi-form-verify-switch", {
                    attrs: {
                      color: "accent darken-2",
                      "color-verified": "accent darken-2"
                    },
                    model: {
                      value: _vm.idVerified,
                      callback: function($$v) {
                        _vm.idVerified = $$v
                      },
                      expression: "idVerified"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-icon",
                { staticClass: "mt-5" },
                [
                  _c(
                    "v-icon",
                    { attrs: { large: "", color: "accent darken-2" } },
                    [_vm._v("mdi-gavel")]
                  )
                ],
                1
              ),
              _c(
                "v-list-item-title",
                {
                  staticClass:
                    "d-flex text-subtitle-1 align-center font-weight-regular"
                },
                [
                  _vm._v(" Disclosures "),
                  _c("v-spacer"),
                  _c("psi-form-verify-switch", {
                    attrs: {
                      color: "accent darken-2",
                      "color-verified": "accent darken-2"
                    },
                    model: {
                      value: _vm.disclosuresVerified,
                      callback: function($$v) {
                        _vm.disclosuresVerified = $$v
                      },
                      expression: "disclosuresVerified"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }