var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      attrs: { icon: "mdi-note", title: "Add Note" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "h3",
        { staticClass: "mb-2 font-weight-regular" },
        [
          _vm._v(" Type"),
          _c("v-icon", { staticClass: "mt-n1", attrs: { color: "error" } }, [
            _vm._v("mdi-circle-medium")
          ])
        ],
        1
      ),
      _c("v-select", {
        attrs: { solo: "", items: _vm.types },
        model: {
          value: _vm.noteType,
          callback: function($$v) {
            _vm.noteType = $$v
          },
          expression: "noteType"
        }
      }),
      _vm.noteType == "Employer"
        ? _c("employer-address-selector", {
            attrs: { items: _vm.employers, selected: _vm.selected }
          })
        : _vm._e(),
      _vm.noteType == "Address"
        ? _c("employer-address-selector", {
            attrs: { items: _vm.addresses, selected: _vm.selected }
          })
        : _vm._e(),
      _c("psi-form-trix-field", {
        attrs: { placeholder: "Enter note" },
        model: {
          value: _vm.note,
          callback: function($$v) {
            _vm.note = $$v
          },
          expression: "note"
        }
      }),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("v-checkbox", {
            attrs: { label: "Internal Only", color: "error" }
          }),
          _c("v-spacer"),
          _c("v-checkbox", {
            attrs: { label: "Include in Recommendation", color: "accent" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }