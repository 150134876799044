var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: _vm.id } },
    [
      _c(
        "psi-card-heading",
        {
          attrs: {
            headingTitle: _vm.title,
            icon: _vm.icon,
            "heading-color": _vm.headingColor
          }
        },
        [
          _c("psi-filter-menu", {
            attrs: {
              filters: _vm.filters,
              "close-on-select": "",
              "close-on-clear": "",
              btnClass: "accent darken-2",
              labelClass: "alternate2 darken-1 white--text  text-subtitle-2"
            },
            on: {
              "filter-item": _vm.filterItem,
              "filter-clear-all": _vm.filterClearAll
            }
          })
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-list",
            { attrs: { subheader: "", dense: "" } },
            [
              _vm._l(_vm.itemKeys, function(key, j) {
                return [
                  _c(
                    "v-subheader",
                    {
                      key: key + "-" + j,
                      staticClass: "text-body-2 font-weight-bold"
                    },
                    [
                      _vm._v(
                        _vm._s(key) +
                          " (" +
                          _vm._s(_vm.getItems(key).length) +
                          ")"
                      )
                    ]
                  ),
                  _vm._l(_vm.getItems(key), function(item, index) {
                    return [
                      _c(
                        "v-list-item",
                        { key: "item-" + index + "-" + key + "-" + j },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "px-0 mx-0 my-1 align-center",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "align-start",
                                  attrs: { cols: "1" }
                                },
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      attrs: {
                                        size: "40",
                                        color: _vm.getColor(key)
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "white--text",
                                          attrs: { size: "30" }
                                        },
                                        [_vm._v(_vm._s(_vm.getIcon(key)))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pl-4 align-center",
                                  attrs: { cols: "11" }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "body-2 font-weight-bold" },
                                    [_vm._v(_vm._s(item.value))]
                                  ),
                                  _c("br"),
                                  _vm._l(item.source, function(source, i) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          key: key + "-source-" + i,
                                          staticClass:
                                            "caption my-1 mr-2 primary--text text--darken-3 text-uppercase",
                                          attrs: {
                                            label: "",
                                            color: _vm.getLabelColor(source),
                                            small: ""
                                          }
                                        },
                                        [_vm._v(_vm._s(source))]
                                      )
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider", {
                        key: "divider-" + key + "-" + index + "-" + j
                      })
                    ]
                  })
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }