var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: _vm.id } },
    [
      _c("psi-detail-card", {
        attrs: {
          icon: _vm.icon,
          headingTitle: _vm.title,
          items: _vm.items,
          "heading-color": _vm.headingColor
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }