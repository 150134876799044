var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: _vm.id } },
    [
      _c(
        "psi-card-heading",
        {
          attrs: {
            headingTitle: _vm.title,
            icon: _vm.icon,
            indicator: _vm.items.length,
            "heading-color": _vm.headingColor
          }
        },
        [
          _c(
            "v-menu",
            {
              attrs: {
                "min-width": "600",
                botom: "",
                left: "",
                "offset-y": "",
                "nudge-top": "-10",
                "close-on-content-click": false,
                "close-on-click": false
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            { attrs: { icon: "" } },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "v-icon",
                            { staticClass: "grey--text text--lighten-2" },
                            [_vm._v("mdi-filter")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.menu,
                callback: function($$v) {
                  _vm.menu = $$v
                },
                expression: "menu"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "secondary white--text text-subtitle-2 py-1"
                    },
                    [
                      _vm._v(" Filter by Activity Types "),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { icon: "", dark: "" } },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: { small: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.menu = false
                                }
                              }
                            },
                            [_vm._v("mdi-close")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-8" },
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "mb-2 font-weight-normal primary--text"
                        },
                        [_vm._v(" Activity Types ")]
                      ),
                      _c("v-select", {
                        attrs: {
                          items: _vm.activityTypes,
                          chips: "",
                          "deletable-chips": "",
                          "item-color": "primary",
                          label: "Activity Types",
                          clearable: "",
                          multiple: "",
                          solo: "",
                          color: "primary",
                          "prepend-inner-icon": "mdi-timeline-text"
                        },
                        on: { change: _vm.filterItems },
                        model: {
                          value: _vm.filter,
                          callback: function($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "mt-n4 mb-2 px-8" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.menu = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.menu = false
                            }
                          }
                        },
                        [_vm._v("Apply")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "perfect-scrollbar",
            { style: _vm.cssVars },
            [
              _c(
                "v-timeline",
                { attrs: { dense: "" } },
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "v-timeline-item",
                    {
                      key: index,
                      attrs: {
                        small: "",
                        icon: _vm.itemIcon(item.activity.type),
                        color: _vm.itemColor(item.activity.type),
                        "icon-color": "white",
                        "fill-dot": ""
                      }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "py-0 my-0",
                          attrs: {
                            color:
                              index % 2 ? "grey lighten-5" : "grey lighten-3"
                          }
                        },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "subtitle-2 py-1" },
                            [_vm._v(_vm._s(item.activity.type) + " Event ")]
                          ),
                          _c(
                            "v-card-subtitle",
                            { staticClass: "caption pt-2 pb-0" },
                            [
                              _vm._v(_vm._s(item.created_at) + " "),
                              item.activity.user
                                ? _c("span", [
                                    _vm._v(
                                      " | by " + _vm._s(item.activity.user)
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _c("v-card-text", { staticClass: "py-1 body-2" }, [
                            _vm._v(" " + _vm._s(item.activity.message) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }