<template>
    <v-card :id="id" color="grey lighten-2">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        ></psi-card-heading>
        <v-card-text>
            <perfect-scrollbar :style="cssVars">
                <div :style="`height:${height}px`"></div>
            </perfect-scrollbar>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-blank",
    components: {},
    props: {
        id: {
            type: String,
            required: false,
            default: "screening-blank",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        title: {
            type: String,
            required: false,
            default: "Blank",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-card-text",
        },
        height: {
            type: [String, Number],
            required: false,
            default: 600,
        },
        maxHeight: {
            type: Number,
            required: false,
            default: 200,
        },
    },
    data() {
        return {};
    },
    computed: {
        cssVars() {
            return {
                "--ps-height": this.maxHeight + "px",
            };
        },
    },
    methods: {},
};
</script>

<style scoped>
.ps {
    height: var(--ps-height);
}
</style>