var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: _vm.id, color: "grey lighten-2" } },
    [
      _c("psi-card-heading", {
        attrs: {
          headingTitle: _vm.title,
          icon: _vm.icon,
          "heading-color": _vm.headingColor
        }
      }),
      _c(
        "v-card-text",
        [
          _c(
            "div",
            { staticClass: "d-flex align-center my-0 py-0" },
            [
              _c("h3", [_vm._v("ORDER ID: " + _vm._s(_vm.items[0].text))]),
              _c("v-spacer"),
              _c("v-switch", {
                staticClass: "text-uppercase font-weight-bold",
                attrs: { label: "Edit", color: "accent darken-2" },
                model: {
                  value: _vm.edit,
                  callback: function($$v) {
                    _vm.edit = $$v
                  },
                  expression: "edit"
                }
              })
            ],
            1
          ),
          _c(
            "v-slide-x-transition",
            [
              _vm.edit
                ? _c("order-details-edit", {
                    attrs: {
                      products: _vm.products,
                      applicant: _vm.applicant,
                      property: _vm.property,
                      screen: _vm.screen
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-slide-x-reverse-transition",
            [
              !_vm.edit
                ? _c("psi-detail-card", {
                    attrs: {
                      "hide-heading": "",
                      icon: _vm.icon,
                      headingTitle: _vm.title,
                      items: _vm.items,
                      "heading-color": _vm.headingColor
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }