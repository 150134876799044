<template>
    <v-card :id="id">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :indicator="items.length"
            :heading-color="headingColor"
        >
            <v-menu
                min-width="600"
                v-model="menu"
                botom
                left
                offset-y
                nudge-top="-10"
                :close-on-content-click="false"
                :close-on-click="false"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs">
                        <v-icon class="grey--text text--lighten-2"
                            >mdi-filter</v-icon
                        >
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title
                        class="secondary white--text text-subtitle-2 py-1"
                    >
                        Filter by Activity Types
                        <v-spacer> </v-spacer>
                        <v-btn icon dark>
                            <v-icon small @click.stop="menu = false"
                                >mdi-close</v-icon
                            >
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="pa-8">
                        <h3 class="mb-2 font-weight-normal primary--text">
                            Activity Types
                        </h3>
                        <v-select
                            v-model="filter"
                            :items="activityTypes"
                            chips
                            deletable-chips
                            item-color="primary"
                            label="Activity Types"
                            clearable
                            multiple
                            solo
                            color="primary"
                            prepend-inner-icon="mdi-timeline-text"
                            @change="filterItems"
                        >
                        </v-select>
                    </v-card-text>
                    <v-card-actions class="mt-n4 mb-2 px-8">
                        <v-spacer></v-spacer>
                        <v-btn class="mr-2" @click.stop="menu = false"
                            >Cancel</v-btn
                        >
                        <v-btn color="primary" @click.stop="menu = false"
                            >Apply</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-menu>
        </psi-card-heading>
        <v-card-text>
            <perfect-scrollbar :style="cssVars">
                <v-timeline dense>
                    <v-timeline-item
                        small
                        :icon="itemIcon(item.activity.type)"
                        :color="itemColor(item.activity.type)"
                        icon-color="white"
                        fill-dot
                        v-for="(item, index) in items"
                        :key="index"
                    >
                        <v-card
                            class="py-0 my-0"
                            :color="
                                index % 2 ? 'grey lighten-5' : 'grey lighten-3'
                            "
                        >
                            <v-card-title class="subtitle-2 py-1"
                                >{{ item.activity.type }} Event
                            </v-card-title>
                            <v-card-subtitle class="caption pt-2 pb-0"
                                >{{ item.created_at }}
                                <span v-if="item.activity.user">
                                    | by {{ item.activity.user }}</span
                                ></v-card-subtitle
                            >
                            <v-card-text class="py-1 body-2">
                                {{ item.activity.message }}
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </perfect-scrollbar>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-activities",
    components: {},
    props: {
        title: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        maxHeight: {
            type: Number,
            required: false,
            default: 800,
        },

        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        icon: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            menu: false,
            filter: [],
        };
    },
    methods: {
        itemColor(activity) {
            const activities = this.$config("screen.activities");
            return this.$_.get(activities, `${activity}.color`, "primary");
        },
        itemIcon(activity) {
            const activities = this.$config("screen.activities");
            return this.$_.get(activities, `${activity}.icon`, "mdi-account");
        },
        filterItems() {},
    },
    computed: {
        cssVars() {
            return {
                "--ps-height": this.maxHeight + "px",
            };
        },
        activityTypes() {
            return Array.from(
                new Set(this.items.map((val) => val.activity.type))
            );
        },
    },
};
</script>

<style>
.v-timeline-item__dot {
    z-index: 1 !important;
}
.ps {
    height: var(--ps-height);
}
</style>