<template>
    <v-card :id="id">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        ></psi-card-heading>
        <v-card-text>
            <v-list>
                <template v-for="(contact, index) in contacts">
                    <v-list-item
                        :key="index"
                        three-line
                        v-if="$_.get(contact, 'type', '')"
                    >
                        <v-list-item-avatar
                            :color="contactTypeColor(contact.type)"
                        >
                            <span class="white--text text-h5">
                                {{ contact.type.charAt(0) }}</span
                            >
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                contact.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ $_.get(contact, "type", "N/A") }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="mt-2">
                                <v-icon small>mdi-email</v-icon>
                                {{ contact.email }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon small>mdi-phone</v-icon>
                                {{ contact.phone }}
                                <span v-if="contact.fax" class="ml-2">
                                    |
                                    <v-icon small class="ml-1">mdi-fax</v-icon>
                                    {{ contact.fax }}</span
                                >
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <psi-action-drop-down
                                :heading="`Contact ${contact.name}`"
                                btnLabel="Contact"
                                :items="contactItems(contact)"
                            ></psi-action-drop-down>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="`divider-${index}`"></v-divider>
                </template>
            </v-list>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-contacts",
    components: {},
    props: {
        contacts: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: "Contacts",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-contacts",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        id: {
            type: String,
            required: false,
            default: "screening-contacts",
        },
    },
    data() {
        return {};
    },
    methods: {
        contactTypeColor(type = null) {
            const contactType = type ? type.split(" ")[0] : "Applicant";
            return this.$_.get(
                this.$config("screen.contacts"),
                `${contactType}.color`,
                "primary"
            );
        },

        contactItems(contact) {
            let items = [];
            for (const key in contact) {
                if (contact[key] && ["email", "fax", "phone"].includes(key)) {
                    items.push({
                        icon: `mdi-${key}`,
                        title: contact[key],
                    });
                    if (key === "phone") {
                        items.push({
                            icon: `mdi-message`,
                            title: contact[key],
                        });
                    }
                }
            }
            return items;
        },
    },
};
</script>

<style scoped>
</style>