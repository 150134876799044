<template>
    <v-card :id="id" class="mt-3">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        >
            <v-switch
                class="mt-0 mb-n2"
                dense
                dark
                label="Matched Only"
                color="accent lighten-2"
                v-model="matched"
            ></v-switch>
        </psi-card-heading>
        <div class="d-flex align-center my-2 mx-4">
            <h3>Recommendation:</h3>
            <v-chip color="secondary" class="ml-2">{{
                scorecard.recommendation
            }}</v-chip>
            <v-chip label class="ml-1 text-uppercase">draft</v-chip>
        </div>
        <v-divider class="my-2"></v-divider>

        <v-card-text>
            <v-data-table
                :headers="headers"
                fixed-header
                :items="items"
                disable-pagination
                disable-sort
                hide-default-footer
            >
                <template v-slot:item.processed="{ item }">
                    <v-icon
                        :color="
                            item.processed
                                ? 'warning darken-2'
                                : 'grey lighten-2'
                        "
                        >mdi-check-circle</v-icon
                    >
                </template>
                <template v-slot:item.recommendation="{ item }">
                    <v-chip small v-if="item.match" color="primary">
                        {{ item.recommendation.action }}
                    </v-chip>
                    <span v-else>
                        {{ item.recommendation.action }}
                    </span>
                </template>
                <template v-slot:item.description="{ item }">
                    <psi-popover open-on-hover>
                        {{ item.description }}
                    </psi-popover>
                </template>
                <template v-slot:item.match="{ item }">
                    <v-icon
                        :color="
                            item.match ? 'primary darken-2' : 'grey lighten-2'
                        "
                        >mdi-check-circle</v-icon
                    >
                </template>
                <template v-slot:item.actual="{ item }">
                    <strong>{{ item.actual }}</strong>
                </template>
                <template v-slot:item.rule="{ item }">
                    {{ item.setting }} <br />
                    <span
                        class="text-caption font-italic grey--text text--darken-1"
                        >{{ item.label }}</span
                    >
                </template>
                <template v-slot:item.Actions="{ item }">
                    <psi-action-drop-down
                        :items="ruleActionMenu(item)"
                        icon="mdi-dots-vertical"
                        iconClass="primary--text"
                        heading="Scorecard Rule Actions"
                        @action="ruleAction($event, item)"
                    ></psi-action-drop-down>
                </template>
            </v-data-table>
        </v-card-text>

        <v-dialog v-model="actionDialog" max-width="800" v-if="actionDialog">
            <screening-process-edit-rule
                :rule="rule"
                @close="actionDialog = false"
            ></screening-process-edit-rule>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-scorecard",
    components: {
        ScreeningProcessEditRule: () => import("./ScreeningProcessEditRule"),
    },
    props: {
        id: {
            type: String,
            required: false,
            default: "screening-scorecard",
        },
        title: {
            type: String,
            required: false,
            default: "Score Card",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-clipboard-list-outline",
        },
        scorecard: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            actionDialog: false,
            matched: true,
            rule: {},
            menu: [
                {
                    icon: "mdi-playlist-edit",
                    title: "Edit Rule",
                },
            ],
            // TODO: create a header class
            headers: [
                {
                    text: "Processed",
                    align: "center",
                    value: "processed",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Matched",
                    align: "center",
                    value: "match",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Category",
                    align: "start",
                    value: "category",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Rule",
                    align: "start",
                    value: "rule",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Actual",
                    align: "start",
                    value: "actual",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Action",
                    align: "start",
                    value: "recommendation",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Description",
                    align: "start",
                    value: "description",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Actions",
                    align: "center",
                    value: "Actions",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
            ],
        };
    },
    computed: {
        items() {
            return this.scorecard.scorecard
                .filter((item) => {
                    return this.matched ? item.match : true;
                })
                .map((item) => {
                    item.actual = this.getActual(item);
                    return item;
                });
        },
    },
    methods: {
        ruleAction(action, item) {
            if (action.title == "Edit Rule") {
                this.rule = item;
                this.actionDialog = true;
            }
        },
        ruleActionMenu(item) {
            if (item.match && item.recommendation.action === "REVIEW") {
                return this.menu.concat([
                    {
                        icon: "mdi-eye-check",
                        title: "Review",
                    },
                ]);
            } else {
                return this.menu;
            }
        },
        getActual(item) {
            let actual = item.actual;

            if (!item.processed) {
                actual = "N/A";
            }
            if (item.type === "Exists") {
                actual = item.actual ? "YES" : "NO";
            }
            return actual;
        },
    },
};
</script>

<style scoped>
</style>