<template>
    <v-card v-if="applicantData">
        <v-card-text>
            <template
                v-for="(
                    {
                        id,
                        name,
                        label,
                        rules,
                        mask,
                        placeholder,
                        required,
                        icon,
                        outerIcon,
                    },
                    index
                ) in fields"
            >
                <psi-form-element
                    :label="label"
                    :required="required"
                    :key="index"
                >
                    <psi-form-text-field
                        :name="name"
                        :icon="icon"
                        :value="applicantData[name]"
                        :rules="rules"
                        :mask="mask"
                        :outer-icon="outerIcon"
                        :placeholder="placeholder"
                        color="accent darken-2"
                        @click:append-outer="handleOuterIconClick(id)"
                    >
                    </psi-form-text-field>
                </psi-form-element>
            </template>
        </v-card-text>
        <v-card-actions class="mr-2">
            <v-spacer></v-spacer>
            <v-btn color="accent darken-2">
                <v-icon class="mr-1">mdi-account</v-icon> Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "applicant-edit-form",
    components: {},
    props: {
        applicant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            applicantData: this.getData(this.applicant),
            fields: [
                {
                    id: this.$uuid.v4(),
                    name: "first_name",
                    label: "First Name",
                    icon: this.$config("icons.first_name"),
                    rules: [(v) => !!v.trim() || "First Name is required"],
                    mask: "",
                    required: true,
                    placeholder: "Enter first name",
                    outerIcon: "",
                },
                {
                    id: this.$uuid.v4(),
                    name: "middle_name",
                    label: "Middle Name",
                    icon: this.$config("icons.middle_name"),
                    rules: [],
                    mask: "",
                    required: false,
                    placeholder: "Enter middle name",
                    outerIcon: "",
                },
                {
                    id: this.$uuid.v4(),
                    name: "last_name",
                    label: "Last Name",
                    icon: this.$config("icons.last_name"),
                    mask: "",
                    rules: [(v) => !!v || "Last Name is required"],
                    required: true,
                    placeholder: "Enter last name",
                    outerIcon: "",
                },
                {
                    id: this.$uuid.v4(),
                    name: "phone",
                    label: "Phone",
                    icon: this.$config("icons.phone"),
                    mask: "(###)-###-####",
                    rules: [
                        (v) =>
                            v.length == 0 ||
                            v.length == 14 ||
                            "Phone number is not valid",
                    ],
                    placeholder: "Enter Phone",
                    required: true,
                    outerIcon: "",
                },
                {
                    id: this.$uuid.v4(),
                    name: "email",
                    label: "Email",
                    icon: this.$config("icons.email"),
                    mask: "",
                    rules: [
                        (v) =>
                            v.length == 0 ||
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                v
                            ) ||
                            "E-mail must be valid",
                    ],
                    required: true,
                    placeholder: "Enter email",
                    outerIcon: "",
                },
                {
                    id: this.$uuid.v4(),
                    name: "birthdate",
                    label: "Date of Birth",
                    mask: "##/##/####",
                    icon: this.$config("icons.birthdate"),
                    rules: [
                        (v) => !!v || "Date of Birth is a required field",
                        (v) =>
                            /^((((0[13578])|([13578])|(1[02]))[/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[/](([1-9])|([0-2][0-9])|(30)))|((2|02)[/](([1-9])|([0-2][0-9]))))[/]\d{4}$|^\d{4}$/.test(
                                v
                            ) || "Date of Birth is not valid",
                    ],
                    required: true,
                    placeholder: "mm/dd/yyyy",
                    outerIcon: "",
                },
                {
                    id: this.$uuid.v4(),
                    name: "ssn_mask",
                    label: "SSN",
                    icon: this.$config("icons.ssn_mask"),
                    mask: "",
                    placeholder: "Enter SSN",
                    rules: [(v) => !!v || "SSN is a required field"],
                    required: true,
                    outerIcon: "mdi-lock-open-variant",
                },
            ],
        };
    },
    methods: {
        handleOuterIconClick(id) {
            const fieldIndex = this.fields.findIndex((item) => item.id === id);
            let field = this.fields[fieldIndex];
            if (
                this.applicantData[field.name].search(new RegExp("\\*")) !== -1
            ) {
                this.applicantData[field.name] =
                    "111-11-" + this.applicantData[field.name].slice(-4);
                field.outerIcon = "mdi-lock";
            } else {
                this.applicantData[field.name] =
                    "***-**-" + this.applicantData[field.name].slice(-4);
                field.outerIcon = "mdi-lock-open-variant";
            }
            this.fields[fieldIndex] = field;
        },
        getData({
            first_name,
            middle_name,
            last_name,
            birthdate,
            phone,
            email,
            ssn_mask,
        }) {
            return {
                first_name,
                middle_name,
                last_name,
                birthdate,
                phone,
                email,
                ssn_mask,
            };
        },
    },
};
</script>

<style scoped>
</style>