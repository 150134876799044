<template>
    <v-card :id="id" color="grey lighten-2">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        >
            <v-tooltip top color="accent darken-2">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="accent darken-2"
                        v-on="on"
                        v-bind="attrs"
                        @click.stop="dialog = true"
                        :disabled="htmlReport.length === 0"
                    >
                        <v-icon>mdi-file-table</v-icon>
                    </v-btn>
                </template>
                <span>View Credit Report</span>
            </v-tooltip>
        </psi-card-heading>
        <v-card-text>
            <div class="mt-4" v-if="$_.has(search, 'hart.ficoScore.score')">
                <h3 class="mb-2">Score</h3>
                <v-card flat>
                    <v-card-text>
                        <credit-score
                            :score="
                                parseInt(
                                    $_.get(search, 'hart.ficoScore.score', 0)
                                )
                            "
                        ></credit-score>
                    </v-card-text>
                </v-card>
                <credit-factors
                    v-if="factors.length"
                    :factors="factors"
                    div-class="mt-3"
                ></credit-factors>
                <credit-alert
                    v-if="alerts.length"
                    :alerts="alerts"
                    class="mt-3"
                ></credit-alert>
                <credit-details
                    v-if="details.length"
                    :details="details"
                ></credit-details>
                <credit-debt
                    v-if="
                        Object.keys(creditBalances).length ||
                        publicRecords.length
                    "
                    :credit-balances="creditBalances"
                    :public-records="publicRecords"
                ></credit-debt>
                <credit-public-records
                    v-if="publicRecords.length"
                    :public-records="publicRecords"
                ></credit-public-records>
                <credit-accounts
                    :collection="collection"
                    :trade="trade"
                    v-if="
                        Object.keys(collection).length ||
                        Object.keys(trade).length
                    "
                ></credit-accounts>
            </div>
            <div class="mt-4" v-else>
                <v-alert border="top" outlined icon="mdi-credit-card-remove">
                    Credit Report Unavailable
                </v-alert>
            </div>
        </v-card-text>
        <v-dialog fullscreen v-model="dialog">
            <psi-dialog
                v-if="dialog"
                title="Credit Report"
                hide-actions
                @close="dialog = false"
            >
                <div
                    v-html="htmlReport"
                    style="max-width: 1040px"
                    class="mx-auto"
                ></div>
            </psi-dialog>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-credit",
    components: {
        CreditScore: () =>
            import("@components/screen/components/Gadget/CreditScore"),
        CreditFactors: () =>
            import("@components/screen/components/Print/CreditFactors"),
        CreditAlert: () =>
            import("@components/screen/components/Credit/CreditAlert"),
        CreditDetails: () =>
            import("@components/screen/components/Credit/CreditDetails"),
        CreditDebt: () =>
            import("@components/screen/components/Credit/CreditDebt"),
        CreditPublicRecords: () =>
            import("@components/screen/components/Credit/CreditPublicRecords"),
        CreditAccounts: () =>
            import("@components/screen/components/Credit/CreditAccounts"),
    },
    props: {
        id: {
            type: String,
            required: false,
            default: "screening-credit",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        title: {
            type: String,
            required: false,
            default: "Blank",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-card-text",
        },
        height: {
            type: [String, Number],
            required: false,
            default: 600,
        },
        maxHeight: {
            type: Number,
            required: false,
            default: 200,
        },
        summary: {
            type: Object,
            required: true,
        },
        reports: {
            type: Array,
            required: true,
        },
        search: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        collection() {
            return this.$_.get(this.search, "hart.collection", {});
        },
        trade() {
            return this.$_.get(this.search, "hart.trade", {});
        },

        publicRecords() {
            return this.$_.get(this.search, "hart.public.details", []);
        },
        creditBalances() {
            return this.$_.get(this.search, "summary.creditBalances", {});
        },
        htmlReport() {
            const report = this.reports.find(
                (report) => report.type === "hart"
            );
            return report != undefined && report != null ? report.html : "";
        },
        factors() {
            return this.$_.has(this.search, "hart.ficoScore")
                ? Object.keys(this.search.hart.ficoScore)
                      .filter((key) => key.search("factor") !== -1)
                      .map((key) => {
                          return this.search.hart.ficoScore[key];
                      })
                : [];
        },
        alerts() {
            return this.$_.has(this.search, "hart.ficoScore")
                ? Object.keys(this.search.hart.ficoScore)
                      .filter((key) => key.toLowerCase().search("alert") !== -1)
                      .map((key) => {
                          return this.search.hart.ficoScore[key];
                      })
                : [];
        },
        details() {
            return this.$_.get(this.search, "hart.subject.details", [])
                .length === 1
                ? Object.keys(this.search.hart.subject.details[0]).map(
                      (key) => {
                          return {
                              label: key,
                              text: this.search.hart.subject.details[0][key],
                          };
                      }
                  )
                : [];
        },
    },
    methods: {},
};
</script>

<style scoped>
.ps {
}
</style>