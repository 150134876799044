<template>
    <v-card :id="id" color="grey lighten-2">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        ></psi-card-heading>
        <v-card-text>
            <v-row>
                <v-col cols="4">
                    <psi-stats-card
                        :color-option="4"
                        icon="mdi-cash-usd"
                        :stat="$dollarFormat(invoice_amount)"
                        hide-actions
                        title="Invoice Amount"
                    >
                    </psi-stats-card>
                </v-col>
                <v-col cols="4">
                    <psi-stats-card
                        :color-option="2"
                        icon="mdi-cash-usd"
                        :stat="$dollarFormat(paid_amount)"
                        hide-actions
                        title="Paid Amount"
                    >
                    </psi-stats-card>
                </v-col>

                <v-col cols="4">
                    <psi-stats-card
                        :color-option="3"
                        icon="mdi-cash-usd"
                        :stat="$dollarFormat(due_amount)"
                        hide-actions
                        title="Due Amount"
                    >
                    </psi-stats-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="invoiceItems"
                        disable-pagination
                        disable-filtering
                        hide-default-footer
                    >
                        <template v-slot:item.status="{ item }">
                            <v-chip
                                small
                                :color="
                                    item.status !== 'Paid'
                                        ? 'warning'
                                        : 'success'
                                "
                            >
                                <span
                                    class="text-uppercase grey--text text--darken-3"
                                    >{{ item.status }}</span
                                >
                            </v-chip>
                        </template>
                        <template v-slot:item.instant="{ item }">
                            <v-chip
                                small
                                :color="item.instant ? 'success' : 'error'"
                            >
                                <v-icon>{{
                                    item.instant ? "mdi-check" : "mdi-close"
                                }}</v-icon>
                            </v-chip>
                        </template>
                        <template v-slot:item.addon="{ item }">
                            <v-chip
                                small
                                :color="item.addon ? 'success' : 'error'"
                            >
                                <v-icon>{{
                                    item.addon ? "mdi-check" : "mdi-close"
                                }}</v-icon>
                            </v-chip>
                        </template>
                        <template v-slot:footer>
                            <v-divider></v-divider>
                            <v-row class="mr-1">
                                <v-col cols="12" class="d-flex">
                                    <v-spacer></v-spacer>
                                    <span
                                        class="text-subtitle-1 font-weight-bold"
                                    >
                                        Invoice Total:
                                        {{ $dollarFormat(invoice_amount) }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-row v-if="paymentItems.length">
                <v-col cols="12">
                    <v-data-table
                        :headers="paymentHeaders"
                        :items="paymentItems"
                        disable-pagination
                        disable-filtering
                        hide-default-footer
                    >
                        <template v-slot:item.receipt_url="{ item }">
                            <a :href="item.receipt_url" target="_blank"
                                ><v-icon>mdi-receipt</v-icon></a
                            >
                        </template>
                        <template v-slot:footer>
                            <v-divider></v-divider>
                            <v-row class="mr-1">
                                <v-col cols="12" class="d-flex">
                                    <v-spacer></v-spacer>
                                    <span
                                        class="text-subtitle-1 font-weight-bold"
                                    >
                                        Payment Total:
                                        {{ $dollarFormat(paid_amount) }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <!-- <psi-detail-card
                        icon="mdi-home-city"
                        hide-heading
                        :items="items"
                    ></psi-detail-card> -->
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-invoice",
    components: {},
    props: {
        id: {
            required: false,
            default: "screening-invoice",
        },
        invoice_amount: {
            type: Number,
            required: false,
        },
        paid_amount: {
            type: Number,
            required: false,
        },
        due_amount: {
            type: Number,
            required: false,
        },
        invoices: {
            type: Array,
            required: true,
        },
        property: {
            type: Object,
            required: true,
        },
        payment: {
            validator: (val) => {
                return val === null || typeof val === "object";
            },
            required: true,
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        title: {
            type: String,
            required: false,
            default: "Blank",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-card-text",
        },
        height: {
            type: [String, Number],
            required: false,
            default: 600,
        },
        maxHeight: {
            type: Number,
            required: false,
            default: 200,
        },
    },
    data() {
        return {
            paymentHeaders: [
                {
                    text: "Payment ID",
                    align: "start",
                    sortable: false,
                    value: "id",
                },
                {
                    text: "Date Paid",
                    align: "start",
                    sortable: false,
                    value: "created_at",
                },
                { text: "Description", sortable: false, value: "description" },
                {
                    text: "Payment Type",
                    sortable: false,
                    value: "type",
                    align: "start",
                },
                {
                    text: "Receipt",
                    sortable: false,
                    value: "receipt_url",
                    align: "center",
                },
                {
                    text: "Paid Amount",
                    sortable: false,
                    value: "amount",
                    align: "end",
                },
            ],
            headers: [
                {
                    text: "Item ID",
                    align: "start",
                    sortable: false,
                    value: "id",
                },
                {
                    text: "Date Invoiced",
                    align: "start",
                    sortable: false,
                    value: "created_at",
                },
                { text: "Product", sortable: false, value: "product" },
                { text: "Status", sortable: false, value: "status" },

                { text: "Add-on", value: "addon", align: "center" },
                { text: "Instant", value: "instant", align: "center" },
                { text: "Amount", value: "amount", align: "end" },
            ],
        };
    },
    computed: {
        items() {
            return [
                {
                    icon: "mdi-domain",
                    label: "Company",
                    text: this.$_.get(this.property, "company.name", ""),
                },
                {
                    icon: "mdi-home-city-outline",
                    label: "Property",
                    text: this.$_.get(this.property, "name", ""),
                },
                {
                    icon: "mdi-account-box-outline",
                    label: "Contact",
                    text: this.$_.get(
                        this.property,
                        "billing_contacts[0].name",
                        ""
                    ),
                },
            ];
        },
        invoiceItems() {
            return this.invoices.map((item) => {
                return {
                    id: item.id,
                    created_at: item.created_at,
                    product: item.product.name,
                    amount: this.$dollarFormat(item.amount),
                    addon: item.product.addon,
                    instant: item.product.instant,
                    status: item.status,
                };
            });
        },
        paymentItems() {
            return this.payment
                ? [
                      {
                          id: this.payment.id,
                          description: this.payment.meta.description,
                          amount: this.$dollarFormat(this.payment.amount),
                          type: this.payment.type,
                          created_at: this.payment.created_at,
                          receipt_url: this.payment.meta.receipt_url,
                      },
                  ]
                : [];
        },
    },
    methods: {},
};
</script>

<style scoped>
</style>