<template>
    <v-card :id="id" color="grey lighten-2">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        ></psi-card-heading>
        <v-card-text>
            <div class="d-flex align-center">
                <h3>{{ applicant.name }}</h3>
                <v-spacer></v-spacer>
                <v-switch
                    label="Edit"
                    class="text-uppercase font-weight-bold"
                    color="accent darken-2"
                    v-model="edit"
                ></v-switch>
            </div>
            <v-slide-x-transition>
                <applicant-edit-form :applicant="applicant" v-if="edit">
                </applicant-edit-form>
            </v-slide-x-transition>
            <v-slide-x-reverse-transition>
                <psi-detail-card
                    v-if="!edit"
                    hide-heading
                    :items="items"
                    class="mt-4"
                ></psi-detail-card>
            </v-slide-x-reverse-transition>
            <applicant-files :files="applicant.files"></applicant-files>

            <applicant-verify></applicant-verify>
            <!-- <perfect-scrollbar :style="cssVars">
                <div :style="`height:${height}px`"></div>
            </perfect-scrollbar> -->
        </v-card-text>
    </v-card>
</template>
<script>
import ApplicantEditForm from "./Applicant/ApplicantEditForm.vue";
import ApplicantFiles from "./Applicant/ApplicantFiles.vue";
import ApplicantVerify from "./Applicant/ApplicantVerify.vue";
export default {
    name: "screening-process-applicant",
    components: {
        ApplicantEditForm,
        ApplicantFiles,
        ApplicantVerify,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: "screening-applicant",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        title: {
            type: String,
            required: false,
            default: "Blank",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-card-text",
        },
        height: {
            type: [String, Number],
            required: false,
            default: 600,
        },
        maxHeight: {
            type: Number,
            required: false,
            default: 200,
        },
        applicant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            items: [],
            edit: null,
        };
    },
    computed: {
        cssVars() {
            return {
                "--ps-height": this.maxHeight + "px",
            };
        },
    },
    watch: {
        applicant: {
            immediate: true,
            handler(applicant) {
                // TODO: Remove , just for demo right now
                applicant.birthdate = "03/13/1999";

                this.setApplicantData(applicant);
            },
        },
    },
    methods: {
        setApplicantData(applicant) {
            [
                {
                    key: "first_name",
                },
                {
                    key: "middle_name",
                },
                {
                    key: "last_name",
                },
                {
                    key: "type",
                },
                {
                    key: "email",
                },
                {
                    key: "phone",
                },
                {
                    key: "birthdate",
                },
                {
                    key: "ssn_mask",
                    label: "SSN",
                },
            ].forEach((item) => {
                this.items.push({
                    icon: this.$config(`icons.${item.key}`),
                    label: this.$_.get(item, "label", "")
                        ? item.label
                        : this.$_.titleCase(item.key),
                    text: this.$_.get(applicant, item.key, ""),
                });
            });
        },
    },
};
</script>

<style scoped>
.ps {
    height: var(--ps-height);
}
</style>