<template>
    <div>
        <h3 class="my-4 font-weight-regular">
            Verify
            <v-spacer></v-spacer>
        </h3>
        <v-list dense>
            <v-list-item>
                <v-list-item-icon class="mt-5">
                    <v-icon large color="accent darken-2"
                        >mdi-account-check</v-icon
                    >
                </v-list-item-icon>
                <v-list-item-title
                    class="d-flex text-subtitle-1 align-center font-weight-regular"
                >
                    Identification
                    <v-spacer></v-spacer>
                    <psi-form-verify-switch
                        color="accent darken-2"
                        color-verified="accent darken-2"
                        v-model="idVerified"
                    ></psi-form-verify-switch>
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-icon class="mt-5">
                    <v-icon large color="accent darken-2">mdi-gavel</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                    class="d-flex text-subtitle-1 align-center font-weight-regular"
                >
                    Disclosures
                    <v-spacer></v-spacer>
                    <psi-form-verify-switch
                        color="accent darken-2"
                        color-verified="accent darken-2"
                        v-model="disclosuresVerified"
                    ></psi-form-verify-switch>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </div>
</template>
<script>
import PsiFormVerifySwitch from "../../../../lib/src/components/forms/PsiFormVerifySwitch.vue";
export default {
    name: "applicant-verify",
    components: { PsiFormVerifySwitch },
    props: {},
    data() {
        return {
            idVerified: false,
            disclosuresVerified: false,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>