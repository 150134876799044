var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: _vm.id } },
    [
      _c("psi-card-heading", {
        attrs: {
          headingTitle: _vm.title,
          icon: _vm.icon,
          "heading-color": _vm.headingColor
        }
      }),
      _c(
        "v-card-text",
        [
          _c(
            "v-list",
            [
              _vm._l(_vm.contacts, function(contact, index) {
                return [
                  _vm.$_.get(contact, "type", "")
                    ? _c(
                        "v-list-item",
                        { key: index, attrs: { "three-line": "" } },
                        [
                          _c(
                            "v-list-item-avatar",
                            {
                              attrs: {
                                color: _vm.contactTypeColor(contact.type)
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "white--text text-h5" },
                                [_vm._v(" " + _vm._s(contact.type.charAt(0)))]
                              )
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(contact.name))
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$_.get(contact, "type", "N/A")) +
                                    " "
                                )
                              ]),
                              _c(
                                "v-list-item-subtitle",
                                { staticClass: "mt-2" },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-email")
                                  ]),
                                  _vm._v(" " + _vm._s(contact.email) + " ")
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-subtitle",
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-phone")
                                  ]),
                                  _vm._v(" " + _vm._s(contact.phone) + " "),
                                  contact.fax
                                    ? _c(
                                        "span",
                                        { staticClass: "ml-2" },
                                        [
                                          _vm._v(" | "),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-fax")]
                                          ),
                                          _vm._v(" " + _vm._s(contact.fax))
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c("psi-action-drop-down", {
                                attrs: {
                                  heading: "Contact " + contact.name,
                                  btnLabel: "Contact",
                                  items: _vm.contactItems(contact)
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider", { key: "divider-" + index })
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }