<template>
    <div :id="id">
        <psi-detail-card
            :icon="icon"
            :headingTitle="title"
            :items="items"
            :heading-color="headingColor"
        >
        </psi-detail-card>
    </div>
</template>
<script>
export default {
    name: "screening-process-flags",
    components: {},
    props: {
        flags: {
            type: Object,
            required: true,
        },
        id: {
            type: String,
            required: false,
            default: "screening-flags",
        },
        title: {
            type: String,
            required: false,
            default: "Flags",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-flag-variant",
        },
    },
    data() {
        return {};
    },
    computed: {
        items() {
            let items = [];
            Object.keys(this.flags).forEach((key) => {
                items.push({
                    icon: "mdi-flag",
                    type: "chip",
                    text: key,
                    label: "",
                    class: this.flags[key]
                        ? "chip--flag align-center d-flex "
                        : "grey--text chip--flag align-center d-flex ",
                    color: this.flags[key] ? "secondary" : "grey lighten-2",
                });
            });
            return items;
        },
    },

    methods: {},
};
</script>

