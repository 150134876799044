var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: _vm.id, color: "grey lighten-2" } },
    [
      _c(
        "psi-card-heading",
        {
          attrs: {
            headingTitle: _vm.title,
            icon: _vm.icon,
            "heading-color": _vm.headingColor
          }
        },
        [
          _c("v-spacer"),
          _c("psi-filter-menu", {
            attrs: {
              filters: _vm.filters,
              btnClass: "accent darken-2",
              labelClass: "alternate2 darken-1 white--text  text-subtitle-2"
            }
          })
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "mt-2" },
        [
          _c(
            "v-row",
            [
              _vm._l(_vm.notes, function(note, index) {
                return [
                  _c(
                    "v-col",
                    { key: index, attrs: { cols: "12", lg: "6", md: "12" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var hover = ref.hover
                                return [
                                  _c(
                                    "v-card",
                                    { attrs: { elevation: hover ? 16 : 2 } },
                                    [
                                      _c("psi-card-heading", {
                                        attrs: {
                                          headingTitle:
                                            note.type + " Note Type",
                                          icon: "mdi-note",
                                          headingColor: "alternate2 darken-1",
                                          headingSubtitle:
                                            note.diff_for_humans +
                                            " | " +
                                            note.user.name
                                        }
                                      }),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "mt-4 mb-n4" },
                                        [
                                          !note.internal
                                            ? _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    icon:
                                                      "mdi-account-multiple",
                                                    text: "",
                                                    color: "grey darken-1",
                                                    prominent: "",
                                                    outlined: ""
                                                  }
                                                },
                                                [
                                                  _c("p", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        note.note
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            : _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    text: "",
                                                    color: "grey darken-2",
                                                    icon: "mdi-account-lock",
                                                    prominent: ""
                                                  }
                                                },
                                                [
                                                  _c("p", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        note.note
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          note.internal
                                            ? _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "text-uppercase ml-2",
                                                  attrs: {
                                                    color: "error darken-2",
                                                    small: ""
                                                  }
                                                },
                                                [_vm._v("Internal")]
                                              )
                                            : _vm._e(),
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "alternate2--text text--darken-1"
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-file-document-edit"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "error--text text--darken-2"
                                                },
                                                [_vm._v("mdi-delete")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }