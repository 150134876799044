<template>
    <v-alert
        icon="mdi-account-alert"
        border="top"
        color="secondary"
        dismissible
        prominent
        dark
    >
        <template v-for="({ message, type }, index) in alerts">
            <v-chip
                small
                class="mr-2"
                :color="
                    type === 'Error' ? 'error darken-2' : 'secondary darken-2'
                "
                :key="index"
                >{{ message }}</v-chip
            >
        </template>
    </v-alert>
</template>
<script>
export default {
    name: "screening-process-alert",
    components: {},
    props: {
        alerts: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>