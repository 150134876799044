var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: _vm.id, color: "grey lighten-2" } },
    [
      _c("psi-card-heading", {
        attrs: {
          headingTitle: _vm.title,
          icon: _vm.icon,
          "heading-color": _vm.headingColor
        }
      }),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("psi-stats-card", {
                    attrs: {
                      "color-option": 4,
                      icon: "mdi-cash-usd",
                      stat: _vm.$dollarFormat(_vm.invoice_amount),
                      "hide-actions": "",
                      title: "Invoice Amount"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("psi-stats-card", {
                    attrs: {
                      "color-option": 2,
                      icon: "mdi-cash-usd",
                      stat: _vm.$dollarFormat(_vm.paid_amount),
                      "hide-actions": "",
                      title: "Paid Amount"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("psi-stats-card", {
                    attrs: {
                      "color-option": 3,
                      icon: "mdi-cash-usd",
                      stat: _vm.$dollarFormat(_vm.due_amount),
                      "hide-actions": "",
                      title: "Due Amount"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.invoiceItems,
                      "disable-pagination": "",
                      "disable-filtering": "",
                      "hide-default-footer": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.status",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  small: "",
                                  color:
                                    item.status !== "Paid"
                                      ? "warning"
                                      : "success"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-uppercase grey--text text--darken-3"
                                  },
                                  [_vm._v(_vm._s(item.status))]
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.instant",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  small: "",
                                  color: item.instant ? "success" : "error"
                                }
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(
                                    _vm._s(
                                      item.instant ? "mdi-check" : "mdi-close"
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item.addon",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  small: "",
                                  color: item.addon ? "success" : "error"
                                }
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(
                                    _vm._s(
                                      item.addon ? "mdi-check" : "mdi-close"
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "footer",
                        fn: function() {
                          return [
                            _c("v-divider"),
                            _c(
                              "v-row",
                              { staticClass: "mr-1" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-subtitle-1 font-weight-bold"
                                      },
                                      [
                                        _vm._v(
                                          " Invoice Total: " +
                                            _vm._s(
                                              _vm.$dollarFormat(
                                                _vm.invoice_amount
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.paymentItems.length
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-data-table", {
                        attrs: {
                          headers: _vm.paymentHeaders,
                          items: _vm.paymentItems,
                          "disable-pagination": "",
                          "disable-filtering": "",
                          "hide-default-footer": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.receipt_url",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: item.receipt_url,
                                        target: "_blank"
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-receipt")])],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "footer",
                              fn: function() {
                                return [
                                  _c("v-divider"),
                                  _c(
                                    "v-row",
                                    { staticClass: "mr-1" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-subtitle-1 font-weight-bold"
                                            },
                                            [
                                              _vm._v(
                                                " Payment Total: " +
                                                  _vm._s(
                                                    _vm.$dollarFormat(
                                                      _vm.paid_amount
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2680183982
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }