<template>
    <psi-dialog
        title="Phone"
        heading-color="secondary"
        btn-color="secondary"
        icon="mdi-phone"
        hide-actions
        class="mx-auto"
        card-text-class="pa-2"
        @close="close"
    >
        <div class="d-flex justify-space-between justify-space-around">
            <v-slide-x-transition>
                <web-phone
                    :contacts="contacts"
                    :applicant="applicant"
                    :order_id="order_id"
                    @show:info="showInfo"
                ></web-phone>
            </v-slide-x-transition>
            <v-slide-x-reverse-transition>
                <div style="max-width: 1060px" v-if="shouldShowInfo">
                    <component
                        :is="info.component"
                        v-bind="componentParams[info.type]"
                        :order_id="info.order_id"
                        :addresses="info.items"
                        :employers="info.items"
                    ></component>
                </div>
            </v-slide-x-reverse-transition>
        </div>
        <psi-confirm-dialog ref="confirm"></psi-confirm-dialog>
    </psi-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "phone-viewer",
    components: {
        WebPhone: () => import("./WebPhone"),
        ScreeningRentalHistory: () =>
            import(
                "@components/screen/components/ScreeningProcessRentalHistory"
            ),
        ScreeningEmploymentHistory: () =>
            import(
                "@components/screen/components/ScreeningProcessEmploymentHistory"
            ),
    },
    props: {
        contacts: {
            type: Array,
            required: true,
        },
        applicant: {
            type: Object,
            required: false,
            default: () => {},
        },
        order_id: {
            type: [String, Number],
            required: false,
            default: 0,
        },
    },
    computed: {
        ...mapGetters("Calls", ["callActive"]),
    },
    data() {
        return {
            componentParams: {
                rental: this.$config("screen.navItems")
                    .filter((item) => item.id === "screening-rental-history")
                    .shift(),
                employment: this.$config("screen.navItems")
                    .filter(
                        (item) => item.id === "screening-employment-history"
                    )
                    .shift(),
            },
            info: {},
            shouldShowInfo: false,
        };
    },

    methods: {
        ...mapActions("Calls", ["hangup"]),
        async close() {
            this.$emit("show:info", false);
            // this should prompt if in an active call
            if (this.callActive) {
                if (
                    await this.$refs.confirm.open(
                        "Confirm",
                        "Are you sure you want to close the phone and hangup the call?",
                        {
                            headingColor: "primary darken-1",
                            buttonColor: "primary darken-1",
                            icon: "mdi-home-map-marker",
                        }
                    )
                ) {
                    this.hangup();
                    this.$emit("close");
                }
            } else {
                // call is not active, close dialog
                this.$emit("close");
            }
        },
        showInfo(show, info) {
            this.shouldShowInfo = show;
            this.info = info;
            this.$emit("show:info", show);
        },
    },
};
</script>

<style scoped>
</style>