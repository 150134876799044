var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-3", attrs: { id: _vm.id } },
    [
      _c(
        "psi-card-heading",
        {
          attrs: {
            headingTitle: _vm.title,
            icon: _vm.icon,
            "heading-color": _vm.headingColor
          }
        },
        [
          _c("v-switch", {
            staticClass: "mt-0 mb-n2",
            attrs: {
              dense: "",
              dark: "",
              label: "Matched Only",
              color: "accent lighten-2"
            },
            model: {
              value: _vm.matched,
              callback: function($$v) {
                _vm.matched = $$v
              },
              expression: "matched"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex align-center my-2 mx-4" },
        [
          _c("h3", [_vm._v("Recommendation:")]),
          _c("v-chip", { staticClass: "ml-2", attrs: { color: "secondary" } }, [
            _vm._v(_vm._s(_vm.scorecard.recommendation))
          ]),
          _c(
            "v-chip",
            { staticClass: "ml-1 text-uppercase", attrs: { label: "" } },
            [_vm._v("draft")]
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-2" }),
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              "fixed-header": "",
              items: _vm.items,
              "disable-pagination": "",
              "disable-sort": "",
              "hide-default-footer": ""
            },
            scopedSlots: _vm._u([
              {
                key: "item.processed",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          color: item.processed
                            ? "warning darken-2"
                            : "grey lighten-2"
                        }
                      },
                      [_vm._v("mdi-check-circle")]
                    )
                  ]
                }
              },
              {
                key: "item.recommendation",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.match
                      ? _c(
                          "v-chip",
                          { attrs: { small: "", color: "primary" } },
                          [
                            _vm._v(
                              " " + _vm._s(item.recommendation.action) + " "
                            )
                          ]
                        )
                      : _c("span", [
                          _vm._v(" " + _vm._s(item.recommendation.action) + " ")
                        ])
                  ]
                }
              },
              {
                key: "item.description",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("psi-popover", { attrs: { "open-on-hover": "" } }, [
                      _vm._v(" " + _vm._s(item.description) + " ")
                    ])
                  ]
                }
              },
              {
                key: "item.match",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          color: item.match
                            ? "primary darken-2"
                            : "grey lighten-2"
                        }
                      },
                      [_vm._v("mdi-check-circle")]
                    )
                  ]
                }
              },
              {
                key: "item.actual",
                fn: function(ref) {
                  var item = ref.item
                  return [_c("strong", [_vm._v(_vm._s(item.actual))])]
                }
              },
              {
                key: "item.rule",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(item.setting) + " "),
                    _c("br"),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-caption font-italic grey--text text--darken-1"
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  ]
                }
              },
              {
                key: "item.Actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("psi-action-drop-down", {
                      attrs: {
                        items: _vm.ruleActionMenu(item),
                        icon: "mdi-dots-vertical",
                        iconClass: "primary--text",
                        heading: "Scorecard Rule Actions"
                      },
                      on: {
                        action: function($event) {
                          return _vm.ruleAction($event, item)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.actionDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "800" },
              model: {
                value: _vm.actionDialog,
                callback: function($$v) {
                  _vm.actionDialog = $$v
                },
                expression: "actionDialog"
              }
            },
            [
              _c("screening-process-edit-rule", {
                attrs: { rule: _vm.rule },
                on: {
                  close: function($event) {
                    _vm.actionDialog = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }