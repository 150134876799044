<template>
    <v-card :id="id" color="grey lighten-2">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        ></psi-card-heading>
        <v-card-text>
            <h3 class="my-2">Crime Records</h3>
            <v-row no-gutters>
                <template v-for="(charge, index) in charges">
                    <v-col cols="6" :key="index">
                        <screen-charge
                            :charge="charge"
                            mode="screen"
                        ></screen-charge>
                    </v-col>
                </template>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import WithCrimeRecords from "@components/screen/mixins/WithCrimeRecords";
export default {
    name: "screening-process-crime",
    components: {
        ScreenCharge: () =>
            import("@components/screen/components/Search/ScreenCharge"),
    },
    mixins: [WithCrimeRecords],
    props: {
        id: {
            type: String,
            required: false,
            default: "screening-crime",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        title: {
            type: String,
            required: false,
            default: "Blank",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-scale-balance",
        },
        height: {
            type: [String, Number],
            required: false,
            default: 600,
        },
        maxHeight: {
            type: Number,
            required: false,
            default: 200,
        },
        summary: {
            type: Object,
            required: true,
        },
        reports: {
            type: Array,
            required: true,
        },
        search: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        getReport(type) {
            return this.reports.find((report) => report.type === type);
        },
    },
    created() {
        this.records = this.$_.get(this.search, "ojin.tags", [])
            .filter(
                (item) =>
                    this.$_.get(item, "disposition", "") !== "DISMISSED" &&
                    this.$_.get(item, "type", "") === "crime"
            )
            .map((item) => {
                item.caseDetails = this.getReport("ojin")["json"].find(
                    (caseDetail) => caseDetail.caseId === item.detail.caseId
                );
                return item;
            });
    },
};
</script>

<style scoped>
</style>