var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: _vm.id, color: "grey lighten-2" } },
    [
      _c("psi-card-heading", {
        attrs: {
          headingTitle: _vm.title,
          icon: _vm.icon,
          "heading-color": _vm.headingColor
        }
      }),
      _c(
        "v-card-text",
        [
          _c("h3", { staticClass: "my-2" }, [_vm._v("Crime Records")]),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _vm._l(_vm.charges, function(charge, index) {
                return [
                  _c(
                    "v-col",
                    { key: index, attrs: { cols: "6" } },
                    [
                      _c("screen-charge", {
                        attrs: { charge: charge, mode: "screen" }
                      })
                    ],
                    1
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }