<template>
    <v-card :id="id" color="grey lighten-2">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        ></psi-card-heading>
        <v-card-text>
            <div class="d-flex align-center my-0 py-0">
                <h3>ORDER ID: {{ items[0].text }}</h3>
                <v-spacer></v-spacer>
                <v-switch
                    label="Edit"
                    class="text-uppercase font-weight-bold"
                    color="accent darken-2"
                    v-model="edit"
                ></v-switch>
            </div>

            <v-slide-x-transition>
                <order-details-edit
                    v-if="edit"
                    :products="products"
                    :applicant="applicant"
                    :property="property"
                    :screen="screen"
                ></order-details-edit>
            </v-slide-x-transition>
            <v-slide-x-reverse-transition>
                <psi-detail-card
                    v-if="!edit"
                    hide-heading
                    :icon="icon"
                    :headingTitle="title"
                    :items="items"
                    :heading-color="headingColor"
                >
                </psi-detail-card>
            </v-slide-x-reverse-transition>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-details",
    components: {
        OrderDetailsEdit: () => import("./Order/OrderDetailsEdit"),
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        products: {
            type: Array,
            required: false,
            default: () => [],
        },
        applicant: {
            type: Object,
            required: true,
        },
        property: {
            type: Object,
            required: true,
        },
        screen: {
            type: Object,
            required: false,
            default: () => {},
        },
        title: {
            type: String,
            required: false,
            default: "Details",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-card-account-details",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        id: {
            type: String,
            required: false,
            default: "screening-details",
        },
    },
    data() {
        return {
            edit: false,
        };
    },
};
</script>

<style scoped>
</style>