<template>
    <v-card :id="id">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        ></psi-card-heading>
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="8" class="mt-2">
                    <psi-stats-card
                        hide-actions
                        :colorOption="2"
                        icon="mdi-home-currency-usd"
                        title="Proposed Rent Amount"
                        :stat="$dollarFormat(rent)"
                        :action="false"
                    >
                    </psi-stats-card>
                </v-col>
                <v-col cols="4" class="mt-2 pl-2">
                    <psi-stats-card
                        :colorOption="3"
                        icon="mdi-aspect-ratio"
                        title="Ratio"
                        :stat="ratio"
                        :action="false"
                        hide-actions
                    >
                    </psi-stats-card>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="mt-2">
                    <household-monthly-income
                        :summary="summary"
                        @view:income="viewHouseholdIncome"
                    ></household-monthly-income>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="incomeSummaryDialog" max-width="800">
            <screening-household-summary-income
                :items="summary.incomes"
                @close="incomeSummaryDialog = false"
            ></screening-household-summary-income>
        </v-dialog>
        <v-dialog v-model="paymentSummaryDialog" max-width="1080">
            <screening-household-summary-payments
                :items="summary.rentals"
                @close:dialog="paymentSummaryDialog = false"
            ></screening-household-summary-payments>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-household-summary",
    components: {
        ScreeningHouseholdSummaryIncome: () =>
            import(
                "@components/screen/components/ScreeningHouseholdSummaryIncome"
            ),
        ScreeningHouseholdSummaryPayments: () =>
            import(
                "@components/screen/components/ScreeningHouseholdSummaryPayments"
            ),
        HouseholdMonthlyIncome: () =>
            import("@components/screen/components/HouseholdMonthlyIncome"),
    },
    props: {
        title: {
            type: String,
            required: false,
            default: "Household Income/Rent",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-clipboard-text-multiple",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        id: {
            type: String,
            required: false,
            default: "screening-household",
        },
        summary: {
            type: Object,
            required: true,
        },
        rent: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            incomeSummaryDialog: false,
            paymentSummaryDialog: false,
        };
    },
    computed: {
        ratio() {
            return (
                this.summary.income_total / this.rent
            ).toLocaleString("en-US", { maximumFractionDigits: 1 });
        },
    },
    methods: {
        viewHouseholdIncome() {
            this.incomeSummaryDialog = true;
        },
        viewPaymentSummary() {
            this.paymentSummaryDialog = true;
        },
    },
};
</script>

<style scoped>
</style>