<template>
    <v-card :id="id">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        >
            <psi-filter-menu
                :filters="filters"
                close-on-select
                close-on-clear
                @filter-item="filterItem"
                @filter-clear-all="filterClearAll"
                btnClass="accent darken-2"
                labelClass="alternate2 darken-1 white--text  text-subtitle-2"
            ></psi-filter-menu>
        </psi-card-heading>
        <v-card-text>
            <v-list subheader dense>
                <template v-for="(key, j) in itemKeys">
                    <v-subheader
                        class="text-body-2 font-weight-bold"
                        :key="`${key}-${j}`"
                        >{{ key }} ({{ getItems(key).length }})</v-subheader
                    >
                    <template v-for="(item, index) in getItems(key)">
                        <v-list-item :key="`item-${index}-${key}-${j}`">
                            <v-row
                                no-gutters
                                class="px-0 mx-0 my-1 align-center"
                            >
                                <v-col cols="1" class="align-start">
                                    <v-avatar size="40" :color="getColor(key)">
                                        <v-icon size="30" class="white--text">{{
                                            getIcon(key)
                                        }}</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="11" class="pl-4 align-center">
                                    <span class="body-2 font-weight-bold">{{
                                        item.value
                                    }}</span
                                    ><br />
                                    <template
                                        v-for="(source, i) in item.source"
                                    >
                                        <v-chip
                                            label
                                            :color="getLabelColor(source)"
                                            :key="`${key}-source-${i}`"
                                            small
                                            class="caption my-1 mr-2 primary--text text--darken-3 text-uppercase"
                                            >{{ source }}</v-chip
                                        >
                                    </template>
                                </v-col>
                            </v-row>
                        </v-list-item>
                        <v-divider
                            :key="`divider-${key}-${index}-${j}`"
                        ></v-divider>
                    </template>
                </template>
            </v-list>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-summary",
    components: {},
    props: {
        id: {
            type: String,
            required: false,
            default: "screening-summary",
        },
        title: {
            type: String,
            required: false,
            default: "Summary",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-clipboard-list-outline",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        maxHeight: {
            type: Number,
            required: false,
            default: 200,
        },
        height: {
            type: [String, Number],
            required: false,
            default: 391,
        },
        summary: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            filters: this.$config("screen.summaryFilters"),
            filterValue: "",
        };
    },
    computed: {
        itemKeys() {
            return ["Addresses", "Names", "Birthdates"].filter((item) => {
                return (
                    this.filterValue.length === 0 || this.filterValue === item
                );
            });
        },
        Addresses() {
            return this.summary.addresses;
        },
        Names() {
            return this.summary.names;
        },
        Birthdates() {
            return this.summary.dobs;
        },
    },
    methods: {
        filterItem(item) {
            if (item.value == null || item.value == undefined) {
                item.value = "";
            }
            this.updateFilter("type", item.value, "value");
            this.filterValue = item.value;
        },
        filterClearAll() {
            this.resetFilters();
            this.filterValue = "";
        },
        resetFilters() {
            this.filters.forEach((item) => {
                this.updateFilter(item.name, item.default, "value");
            });
        },
        // update a filter item value on the filters data
        updateFilter(filterName, data, key = "items") {
            const index = this.filters.findIndex(
                (item) => item.name == filterName
            );
            if (index !== -1) {
                let filter = this.filters[index];
                filter[key] = data;
                this.filters.splice(index, 1, filter);
            }
        },

        getItems(key) {
            return this[key];
        },
        getColor(key) {
            switch (key) {
                case "Addresses":
                    return "secondary";
                case "Names":
                    return "alternate1";
                case "Birthdates":
                    return "alternate2";
            }
        },
        getIcon(key) {
            switch (key) {
                case "Addresses":
                    return "mdi-home-map-marker";
                case "Names":
                    return "mdi-account";
                case "Birthdates":
                    return "mdi-calendar";
            }
        },
        getLabelColor(source) {
            switch (source) {
                case "primary":
                    return "secondary lighten-2";
                case "ojin":
                    return "secondary lighten-3";
                case "hart":
                    return "primary lighten-2";
                case "npd":
                    return "primary lighten-3";
                case "cic":
                    return "alternate1";
            }
        },
    },
};
</script>

<style scoped>
</style>