var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: _vm.id, color: "grey lighten-2" } },
    [
      _c("psi-card-heading", {
        attrs: {
          headingTitle: _vm.title,
          icon: _vm.icon,
          "heading-color": _vm.headingColor
        }
      }),
      _c(
        "v-card-text",
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("h3", [_vm._v(_vm._s(_vm.applicant.name))]),
              _c("v-spacer"),
              _c("v-switch", {
                staticClass: "text-uppercase font-weight-bold",
                attrs: { label: "Edit", color: "accent darken-2" },
                model: {
                  value: _vm.edit,
                  callback: function($$v) {
                    _vm.edit = $$v
                  },
                  expression: "edit"
                }
              })
            ],
            1
          ),
          _c(
            "v-slide-x-transition",
            [
              _vm.edit
                ? _c("applicant-edit-form", {
                    attrs: { applicant: _vm.applicant }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-slide-x-reverse-transition",
            [
              !_vm.edit
                ? _c("psi-detail-card", {
                    staticClass: "mt-4",
                    attrs: { "hide-heading": "", items: _vm.items }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("applicant-files", { attrs: { files: _vm.applicant.files } }),
          _c("applicant-verify")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }