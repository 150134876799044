<template>
    <v-card :id="id" color="grey lighten-2">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        >
            <v-spacer></v-spacer>
            <psi-filter-menu
                :filters="filters"
                btnClass="accent darken-2"
                labelClass="alternate2 darken-1 white--text  text-subtitle-2"
            ></psi-filter-menu>
        </psi-card-heading>
        <v-card-text class="mt-2">
            <!-- <perfect-scrollbar :style="cssVars">
                <div :style="`height:${height}px`"> -->
            <v-row>
                <template v-for="(note, index) in notes">
                    <v-col cols="12" lg="6" md="12" :key="index">
                        <v-hover v-slot="{ hover }">
                            <v-card :elevation="hover ? 16 : 2">
                                <psi-card-heading
                                    :headingTitle="`${note.type} Note Type`"
                                    icon="mdi-note"
                                    headingColor="alternate2 darken-1"
                                    :headingSubtitle="`${note.diff_for_humans} | ${note.user.name}`"
                                >
                                </psi-card-heading>

                                <v-card-text class="mt-4 mb-n4">
                                    <v-alert
                                        v-if="!note.internal"
                                        icon="mdi-account-multiple"
                                        text
                                        color="grey darken-1"
                                        prominent
                                        outlined
                                    >
                                        <p v-html="note.note"></p>
                                    </v-alert>
                                    <v-alert
                                        v-else
                                        outlined
                                        text
                                        color="grey darken-2"
                                        icon="mdi-account-lock"
                                        prominent
                                    >
                                        <p v-html="note.note"></p>
                                    </v-alert>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-chip
                                        v-if="note.internal"
                                        color="error darken-2"
                                        small
                                        class="text-uppercase ml-2"
                                        >Internal</v-chip
                                    >

                                    <v-spacer></v-spacer>

                                    <v-btn icon>
                                        <v-icon
                                            class="alternate2--text text--darken-1"
                                            >mdi-file-document-edit</v-icon
                                        >
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon
                                            class="error--text text--darken-2"
                                            >mdi-delete</v-icon
                                        >
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-hover>
                    </v-col>
                </template>
            </v-row>
            <!-- </div>
            </perfect-scrollbar> -->
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-notes-listing",
    components: {},
    props: {
        notes: {
            type: Array,
            required: true,
        },
        id: {
            type: String,
            required: false,
            default: "screening-blank",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        title: {
            type: String,
            required: false,
            default: "Blank",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-card-text",
        },
        height: {
            type: [String, Number],
            required: false,
            default: 200,
        },
        maxHeight: {
            type: Number,
            required: false,
            default: 200,
        },
    },
    data() {
        return {
            filters: this.$config("screen.notesFilters"),
        };
    },
    computed: {
        cssVars() {
            return {
                "--ps-height": this.maxHeight + "px",
            };
        },
    },
    methods: {},
};
</script>

<style scoped>
.ps {
    height: var(--ps-height);
}
</style>