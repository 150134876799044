<template>
    <psi-dialog icon="mdi-note" title="Add Note" @close="$emit('close')">
        <h3 class="mb-2 font-weight-regular">
            Type<v-icon class="mt-n1" color="error">mdi-circle-medium</v-icon>
        </h3>
        <v-select solo :items="types" v-model="noteType"> </v-select>

        <employer-address-selector
            :items="employers"
            :selected="selected"
            v-if="noteType == 'Employer'"
        ></employer-address-selector>

        <employer-address-selector
            :items="addresses"
            :selected="selected"
            v-if="noteType == 'Address'"
        ></employer-address-selector>

        <psi-form-trix-field
            v-model="note"
            placeholder="Enter note"
        ></psi-form-trix-field>

        <div class="d-flex">
            <v-checkbox label="Internal Only" color="error"></v-checkbox>
            <v-spacer></v-spacer>
            <v-checkbox
                label="Include in Recommendation"
                color="accent"
            ></v-checkbox>
        </div>
    </psi-dialog>
</template>
<script>
export default {
    name: "screening-process-note",
    components: {
        EmployerAddressSelector: () => import("./EmployerAddressSelector"),
    },
    props: {
        order_id: {
            type: [String, Number],
            required: true,
        },
        addresses: {
            type: Array,
            required: false,
            default: () => {
                return [];
            },
        },
        employers: {
            type: Array,
            required: false,
            default: () => {
                return [];
            },
        },
        types: {
            type: Array,
            required: false,
            default: () => {
                return ["Screening", "Employer", "Address"];
            },
        },
        selected: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            note: "",
            noteType: null,
            noteTypes: this.types,
        };
    },
    created() {
        this.noteType = this.type ? this.type : this.types[0];
    },
    methods: {},
};
</script>

<style>
.trix-button--icon-attach {
    display: none !important;
}
.trix-button-group--file-tools {
    display: none !important;
}
.trix-content {
    min-height: 12em !important;
}
</style>
