var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: _vm.id } },
    [
      _c("psi-card-heading", {
        attrs: {
          headingTitle: _vm.title,
          icon: _vm.icon,
          "heading-color": _vm.headingColor
        }
      }),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "mt-2", attrs: { cols: "8" } },
                [
                  _c("psi-stats-card", {
                    attrs: {
                      "hide-actions": "",
                      colorOption: 2,
                      icon: "mdi-home-currency-usd",
                      title: "Proposed Rent Amount",
                      stat: _vm.$dollarFormat(_vm.rent),
                      action: false
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "mt-2 pl-2", attrs: { cols: "4" } },
                [
                  _c("psi-stats-card", {
                    attrs: {
                      colorOption: 3,
                      icon: "mdi-aspect-ratio",
                      title: "Ratio",
                      stat: _vm.ratio,
                      action: false,
                      "hide-actions": ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "mt-2", attrs: { cols: "12" } },
                [
                  _c("household-monthly-income", {
                    attrs: { summary: _vm.summary },
                    on: { "view:income": _vm.viewHouseholdIncome }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.incomeSummaryDialog,
            callback: function($$v) {
              _vm.incomeSummaryDialog = $$v
            },
            expression: "incomeSummaryDialog"
          }
        },
        [
          _c("screening-household-summary-income", {
            attrs: { items: _vm.summary.incomes },
            on: {
              close: function($event) {
                _vm.incomeSummaryDialog = false
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1080" },
          model: {
            value: _vm.paymentSummaryDialog,
            callback: function($$v) {
              _vm.paymentSummaryDialog = $$v
            },
            expression: "paymentSummaryDialog"
          }
        },
        [
          _c("screening-household-summary-payments", {
            attrs: { items: _vm.summary.rentals },
            on: {
              "close:dialog": function($event) {
                _vm.paymentSummaryDialog = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }