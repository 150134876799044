<template>
    <div>
        <h3 class="my-4 d-flex font-weight-regular">
            Files
            <v-spacer></v-spacer>
            <v-btn
                small
                color="accent darken-2"
                dark
                @click.stop="dialog = true"
            >
                <v-icon small class="mr-1">mdi-file-upload</v-icon>Add File
            </v-btn>
        </h3>

        <v-list dense>
            <template v-for="(file, index) in applicantFiles">
                <v-list-item :key="index">
                    <v-list-item-icon class="align-items-center">
                        <v-icon class="" color="accent darken-2"
                            >mdi-file</v-icon
                        >
                    </v-list-item-icon>
                    <v-list-item-title
                        class="text-subtitle-2 d-flex align-center"
                    >
                        <a :href="$_.get(file, 'url', '')" target="_blank">{{
                            file.type
                        }}</a>
                        <v-spacer></v-spacer>
                        <div class="d-flex">
                            <v-tooltip top color="accent darken-2">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        color="accent darken-2"
                                        v-on="on"
                                        v-bind="attrs"
                                        class="mx-1"
                                    >
                                        <v-icon>mdi-open-in-app</v-icon>
                                    </v-btn>
                                </template>
                                <span>View {{ file.type }} file</span>
                            </v-tooltip>

                            <v-divider vertical></v-divider>
                            <v-tooltip top color="accent darken-2">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        color="accent darken-2"
                                        v-on="on"
                                        v-bind="attrs"
                                        class="mx-1"
                                        @click.stop="dialog = true"
                                    >
                                        <v-icon>mdi-file-replace</v-icon>
                                    </v-btn>
                                </template>
                                <span>Replace {{ file.type }} file</span>
                            </v-tooltip>
                            <v-divider vertical></v-divider>
                            <v-tooltip top color="accent darken-2">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        color="accent darken-2"
                                        v-on="on"
                                        v-bind="attrs"
                                        class="mx-1"
                                        @click.stop="removeFile(file.id)"
                                    >
                                        <v-icon>mdi-file-remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remove {{ file.type }} file</span>
                            </v-tooltip>
                        </div>
                    </v-list-item-title>
                </v-list-item>
                <v-divider :key="`divider-${index}`"></v-divider>
            </template>
        </v-list>
        <v-dialog v-model="dialog" max-width="600">
            <psi-dialog
                title="Add File"
                icon="mdi-file-upload"
                v-if="dialog"
                headingColor="accent darken-2"
                hide-actions
                @close="dialog = false"
            >
                <psi-form-file-upload
                    name="Applicant File"
                ></psi-form-file-upload>
            </psi-dialog>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: "applicant-files",
    components: {},
    props: {
        files: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            applicantFiles: [],
            dialog: null,
        };
    },
    computed: {},
    watch: {
        files: {
            immediate: true,
            handler(files) {
                this.applicantFiles = files.filter((file) =>
                    [
                        "Application",
                        "ID Verification",
                        "Authorization",
                        "Other",
                    ].includes(file.type)
                );
            },
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        removeFile(id) {
            this.applicantFiles = this.applicantFiles.filter(
                (file) => file.id !== id
            );
        },
    },
};
</script>

<style scoped>
</style>