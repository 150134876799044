var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: _vm.id } },
    [
      _c("psi-card-heading", {
        attrs: {
          icon: _vm.icon,
          headingTitle: _vm.title,
          headingColor: _vm.headingColor,
          indicator: _vm.notifications.length
        }
      }),
      _c(
        "v-card-text",
        { staticClass: "pt-2" },
        [
          _vm._l(_vm.notifications, function(notification, index) {
            return [
              _c(
                "v-alert",
                {
                  key: index,
                  attrs: {
                    dark: "",
                    prominent: "",
                    border: "top",
                    color:
                      index % 2 == 0
                        ? "alternate2 lighten-1"
                        : "alternate2 darken-1",
                    icon: "mdi-alert-circle"
                  }
                },
                [_vm._v(_vm._s(notification.body))]
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }