<template>
    <v-card :id="id" color="grey lighten-2">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        ></psi-card-heading>
        <v-card-text class="py-2">
            <v-tabs icons-and-text v-model="tab" color="primary">
                <template v-for="(address, index) in addresses">
                    <v-tab :key="`tab-${index}`">
                        {{ address.type }} Address
                        <v-badge
                            :icon="
                                address.is_verified ? 'mdi-check' : 'mdi-close'
                            "
                            overlap
                            bordered
                            :color="
                                address.is_verified
                                    ? 'secondary'
                                    : 'warning darken-2'
                            "
                        >
                            <v-icon large class="primary--text">{{
                                address.type === "Current"
                                    ? "mdi-calendar-cursor"
                                    : "mdi-calendar-clock"
                            }}</v-icon>
                        </v-badge>
                    </v-tab>
                </template>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <template v-for="(address, index) in addresses">
                    <v-tab-item :key="index">
                        <rental-address
                            :address="address"
                            :order_id="order_id"
                        ></rental-address>
                    </v-tab-item>
                </template>
            </v-tabs-items>
            <v-btn
                absolute
                top
                right
                color="primary"
                class="mt-15 mr-2"
                large
                @click.stop="dialog = true"
            >
                <v-icon large>mdi-home-map-marker</v-icon>
                Add Address</v-btn
            >
        </v-card-text>
        <v-dialog v-model="dialog" max-width="800">
            <psi-dialog
                heading-color="primary darken-1"
                title="Add Address"
                scrollable
                icon="mdi-home-map-marker"
                @close="dialog = false"
                v-if="dialog"
                hide-actions
            >
                <rental-address-edit
                    @close="dialog = false"
                ></rental-address-edit>
            </psi-dialog>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-rental-history",
    components: {
        RentalAddress: () => import("./RentalAddress"),
        RentalAddressEdit: () => import("./RentalAddress/RentalAddressEdit"),
    },
    props: {
        order_id: {
            type: [String, Number],
            required: true,
        },
        addresses: {
            type: Array,
            required: true,
        },
        id: {
            type: String,
            required: false,
            default: "screening-rental-history",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        title: {
            type: String,
            required: false,
            default: "Blank",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-card-text",
        },
    },
    data() {
        return {
            tab: null,
            dialog: false,
        };
    },
    computed: {},
    methods: {},
};
</script>

<style scoped>
</style>