var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: _vm.id } },
    [
      _c("psi-card-heading", {
        attrs: {
          "heading-color": _vm.headingColor,
          headingTitle: _vm.title,
          icon: _vm.icon,
          indicator: _vm.tasks.length
        }
      }),
      _c(
        "v-card-text",
        [
          _c(
            "h4",
            { staticClass: "font-weight-regular mt-2 mb-1 primary--text" },
            [_vm._v(" Progress " + _vm._s(_vm.progress) + "% ")]
          ),
          _c("v-progress-linear", {
            attrs: { value: _vm.progress, height: "8", rounded: "" }
          }),
          _c(
            "v-list",
            [
              _vm._l(_vm.tasks, function(task, index) {
                return [
                  _c(
                    "v-list-item",
                    { key: index },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: task.complete
                                  ? "primary darken-2"
                                  : "grey lighten-2"
                              }
                            },
                            [_vm._v("mdi-check-circle")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "primary--text text--darken-2" },
                            [_vm._v(_vm._s(task.label))]
                          ),
                          task.complete
                            ? _c(
                                "v-list-item-subtitle",
                                { staticClass: "primary--text" },
                                [
                                  _vm._v(
                                    " by " +
                                      _vm._s(task.user.name) +
                                      " " +
                                      _vm._s(task.completed_timespan) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("psi-popover", { attrs: { title: task.label } }, [
                            _vm._v(" " + _vm._s(task.hint) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { key: "divider-" + index })
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }