var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.initializing
      ? _c(
          "div",
          [
            _c("h3", { staticClass: "primary--text mb-2" }, [
              _vm._v("Loading order ...")
            ]),
            _c("v-progress-linear", {
              attrs: {
                height: "10",
                color: "primary",
                rounded: "",
                indeterminate: ""
              }
            })
          ],
          1
        )
      : _c(
          "div",
          [
            _c("psi-page-title", {
              attrs: {
                title: _vm.$_.get(_vm.currentScreen, "applicant.name", ""),
                icon: _vm.page.icon,
                breadcrumbs: _vm.breadcrumbs
              },
              on: { "breadcrumbs-item-selected": _vm.breadCrumbItemSelected }
            }),
            _c(
              "v-card",
              { staticClass: "mt-4 px-3 py-5" },
              [
                _c("screening-process-alert", {
                  attrs: {
                    alerts: _vm.$_.get(_vm.currentScreen, "screen.alerts", [])
                  }
                }),
                _c("screening-process-toolbar", {
                  on: {
                    "quicklink:click": function($event) {
                      return _vm.scrollTo($event)
                    },
                    action: function($event) {
                      return _vm.handleToolbarAction($event)
                    }
                  }
                }),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "8", md: "8", sm: "12" } },
                      [
                        _c(
                          "v-row",
                          [
                            _vm._l(_vm.contentGroups, function(group, index) {
                              return [
                                _c(
                                  "v-col",
                                  {
                                    key: "main-" + index,
                                    class: group.colsClass
                                  },
                                  [
                                    _vm._l(group.items, function(item, i) {
                                      return [
                                        _c(
                                          item.component,
                                          _vm._b(
                                            {
                                              key: "item-" + index + "-" + i,
                                              tag: "component",
                                              class: i > 0 ? "mt-6" : "",
                                              attrs: {
                                                id: item.id,
                                                title: item.title,
                                                headingColor: _vm.$_.get(
                                                  item,
                                                  "headingColor",
                                                  "primary"
                                                ),
                                                icon: item.icon,
                                                height: _vm.$_.get(
                                                  item,
                                                  "height",
                                                  ""
                                                ),
                                                maxHeight: item.maxHeight
                                              }
                                            },
                                            "component",
                                            _vm.getItemProps(item),
                                            false
                                          )
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "mt-3",
                        attrs: { cols: "12", lg: "4", md: "4", sm: "12" }
                      },
                      [
                        _vm._l(_vm.sideBarItems, function(item, index) {
                          return [
                            _c(
                              item.component,
                              _vm._b(
                                {
                                  key: index,
                                  tag: "component",
                                  staticClass: "mb-6",
                                  attrs: {
                                    headingColor: _vm.$_.get(
                                      item,
                                      "headingColor",
                                      "primary"
                                    ),
                                    id: item.id,
                                    title: item.title,
                                    icon: item.icon,
                                    height: _vm.$_.get(item, "height", ""),
                                    maxHeight: item.maxHeight
                                  }
                                },
                                "component",
                                _vm.getItemProps(item),
                                false
                              )
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "800" },
                model: {
                  value: _vm.noteDialog,
                  callback: function($$v) {
                    _vm.noteDialog = $$v
                  },
                  expression: "noteDialog"
                }
              },
              [
                _vm.noteDialog
                  ? _c("screening-note", {
                      attrs: {
                        order_id: _vm.currentScreen.order_id,
                        addresses: _vm.$_.get(
                          _vm.currentScreen,
                          "applicant.addresses",
                          []
                        ),
                        employers: _vm.$_.get(
                          _vm.currentScreen,
                          "applicant.employers",
                          []
                        )
                      },
                      on: {
                        close: function($event) {
                          _vm.noteDialog = false
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "1280" },
                model: {
                  value: _vm.applicationDialog,
                  callback: function($$v) {
                    _vm.applicationDialog = $$v
                  },
                  expression: "applicationDialog"
                }
              },
              [
                _vm.applicationDialog
                  ? _c("screening-application", {
                      attrs: {
                        application_id: _vm.$_.get(
                          _vm.currentScreen,
                          "applicant.application.id",
                          {}
                        )
                      },
                      on: {
                        close: function($event) {
                          _vm.applicationDialog = false
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: {
                  "max-width": _vm.phoneDialogFullscreen ? "" : "600",
                  fullscreen: _vm.phoneDialogFullscreen,
                  persistent: ""
                },
                model: {
                  value: _vm.phoneDialog,
                  callback: function($$v) {
                    _vm.phoneDialog = $$v
                  },
                  expression: "phoneDialog"
                }
              },
              [
                _vm.phoneDialog
                  ? _c("phone-viewer", {
                      attrs: {
                        contacts: _vm.currentScreen.contacts,
                        applicant: _vm.currentScreen.applicant,
                        order_id: _vm.currentScreen.order_id
                      },
                      on: {
                        "show:info": _vm.showCallInfo,
                        close: function($event) {
                          _vm.phoneDialog = false
                          _vm.phoneDialogFullscreen = false
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }