<template>
    <v-toolbar
        color="accent darken-1"
        class="fixed-toolbar py-1"
        :height="$vuetify.breakpoint.mdAndUp ? 76 : 48"
    >
        <!-- <v-app-bar-nav-icon dark @click="appBarIcon"></v-app-bar-nav-icon> -->
        <psi-quick-links
            :three-col="$vuetify.breakpoint.mdAndUp"
            :items="$config('screen.navItems').filter((item) => item.visible)"
            :small="$vuetify.breakpoint.mdAndDown"
            btn-class="ml-n2 white--text"
            @action="$emit('quicklink:click', $event)"
        ></psi-quick-links>
        <v-toolbar-title class="white--text text-md-h6 text-subtitle-1">
            {{ currentScreen.applicant.type }}
        </v-toolbar-title>
        <v-divider
            vertical
            dark
            inset
            class="accent lighten-1 mx-4 hidden-md-and-down"
        ></v-divider>
        <v-chip
            :color="currentScreen.status.color"
            class="mr-4 d-none d-lg-block"
        >
            {{ currentScreen.status.label }}
        </v-chip>
        <v-chip
            v-if="
                currentScreen.status != 'Completed' &&
                $_.has(currentScreen, 'screen.time_pending')
            "
            color="error"
            class="d-none d-lg-block"
        >
            {{ currentScreen.screen.time_pending }}
        </v-chip>
        <v-divider
            vertical
            dark
            inset
            class="accent lighten-1 mx-4 hidden-sm-and-down"
        ></v-divider>
        <div
            style="width: 25%"
            class="flex-d flex-column align-start d-none d-md-flex"
        >
            <div class="grey--text text--lighten-1 text-body-2 mt-n3 mb-1">
                Progress {{ currentScreen.screen.progress }}%
            </div>

            <v-progress-linear
                color="accent lighten-2"
                top
                :value="currentScreen.screen.progress"
                rounded
                height="14"
                background-color="accent darken-3"
            ></v-progress-linear>
        </div>
        <v-spacer></v-spacer>
        <v-divider
            vertical
            dark
            inset
            class="accent lighten-1 mx-4 hidden-sm-and-down"
        ></v-divider>
        <v-badge
            :content="currentScreen.applicant.application.applicants.length"
            overlap
            color="warning darken-2 "
            class="mr-3 hidden-sm-and-down"
        >
            <v-btn
                icon
                dark
                @click="$emit('action', 'screening-toolbar-application')"
            >
                <v-icon large>mdi-clipboard-text-multiple</v-icon>
            </v-btn>
        </v-badge>

        <v-badge
            :content="currentScreen.screen.notifications.length"
            overlap
            color="info"
            class="mr-4 hidden-sm-and-down"
        >
            <v-btn
                icon
                dark
                @click.stop="
                    $emit('quicklink:click', { id: 'screening-notifications' })
                "
            >
                <v-icon large>mdi-bell</v-icon>
            </v-btn>
        </v-badge>
        <v-divider
            vertical
            dark
            inset
            class="accent lighten-1 mx-4 hidden-sm-and-down"
        ></v-divider>
        <template v-for="(item, index) in items">
            <v-tooltip
                v-if="item.type === 'button'"
                top
                color="accent"
                :key="index"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        dark
                        class="mr-4 hidden-md-and-down"
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="$emit('action', item.event)"
                    >
                        <v-icon large>{{ item.icon }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ item.tooltip }}</span>
            </v-tooltip>
            <v-divider
                v-if="item.type === 'divider'"
                :key="`divider-${index}`"
                vertical
                dark
                inset
                class="accent lighten-1 mx-4 hidden-md-and-down"
            ></v-divider>
        </template>
        <v-btn
            icon
            dark
            :class="{ 'mr-4': $vuetify.breakpoint.mdAndUp }"
            :small="$vuetify.mdAndDown"
        >
            <v-icon :large="$vuetify.mdAndUp" :small="$vuetify.mdAndDown"
                >mdi-dots-vertical</v-icon
            >
        </v-btn>
    </v-toolbar>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "screening-process-toolbar",
    components: {},
    props: {},
    data() {
        return {
            items: this.$config("screen.processToolBarItems"),
        };
    },
    computed: {
        ...mapGetters("Screens", ["currentScreen", "loading"]),
    },
    watch: {},
    methods: {},
};
</script>

<style scoped>
</style>