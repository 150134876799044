<template>
    <v-card :id="id">
        <psi-card-heading
            :icon="icon"
            :headingTitle="title"
            :headingColor="headingColor"
            :indicator="notifications.length"
        ></psi-card-heading>
        <v-card-text class="pt-2">
            <template v-for="(notification, index) in notifications">
                <v-alert
                    :key="index"
                    dark
                    prominent
                    border="top"
                    :color="
                        index % 2 == 0
                            ? 'alternate2 lighten-1'
                            : 'alternate2 darken-1'
                    "
                    icon="mdi-alert-circle"
                    >{{ notification.body }}</v-alert
                >
            </template>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "screening-notifications",
    components: {},
    props: {
        id: {
            type: String,
            required: false,
            default: "screening-notifications",
        },
        title: {
            type: String,
            required: false,
            default: "Notifications",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-bell",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        notifications: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>