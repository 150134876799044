var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      staticClass: "fixed-toolbar py-1",
      attrs: {
        color: "accent darken-1",
        height: _vm.$vuetify.breakpoint.mdAndUp ? 76 : 48
      }
    },
    [
      _c("psi-quick-links", {
        attrs: {
          "three-col": _vm.$vuetify.breakpoint.mdAndUp,
          items: _vm.$config("screen.navItems").filter(function(item) {
            return item.visible
          }),
          small: _vm.$vuetify.breakpoint.mdAndDown,
          "btn-class": "ml-n2 white--text"
        },
        on: {
          action: function($event) {
            return _vm.$emit("quicklink:click", $event)
          }
        }
      }),
      _c(
        "v-toolbar-title",
        { staticClass: "white--text text-md-h6 text-subtitle-1" },
        [_vm._v(" " + _vm._s(_vm.currentScreen.applicant.type) + " ")]
      ),
      _c("v-divider", {
        staticClass: "accent lighten-1 mx-4 hidden-md-and-down",
        attrs: { vertical: "", dark: "", inset: "" }
      }),
      _c(
        "v-chip",
        {
          staticClass: "mr-4 d-none d-lg-block",
          attrs: { color: _vm.currentScreen.status.color }
        },
        [_vm._v(" " + _vm._s(_vm.currentScreen.status.label) + " ")]
      ),
      _vm.currentScreen.status != "Completed" &&
      _vm.$_.has(_vm.currentScreen, "screen.time_pending")
        ? _c(
            "v-chip",
            { staticClass: "d-none d-lg-block", attrs: { color: "error" } },
            [_vm._v(" " + _vm._s(_vm.currentScreen.screen.time_pending) + " ")]
          )
        : _vm._e(),
      _c("v-divider", {
        staticClass: "accent lighten-1 mx-4 hidden-sm-and-down",
        attrs: { vertical: "", dark: "", inset: "" }
      }),
      _c(
        "div",
        {
          staticClass: "flex-d flex-column align-start d-none d-md-flex",
          staticStyle: { width: "25%" }
        },
        [
          _c(
            "div",
            {
              staticClass: "grey--text text--lighten-1 text-body-2 mt-n3 mb-1"
            },
            [
              _vm._v(
                " Progress " + _vm._s(_vm.currentScreen.screen.progress) + "% "
              )
            ]
          ),
          _c("v-progress-linear", {
            attrs: {
              color: "accent lighten-2",
              top: "",
              value: _vm.currentScreen.screen.progress,
              rounded: "",
              height: "14",
              "background-color": "accent darken-3"
            }
          })
        ],
        1
      ),
      _c("v-spacer"),
      _c("v-divider", {
        staticClass: "accent lighten-1 mx-4 hidden-sm-and-down",
        attrs: { vertical: "", dark: "", inset: "" }
      }),
      _c(
        "v-badge",
        {
          staticClass: "mr-3 hidden-sm-and-down",
          attrs: {
            content: _vm.currentScreen.applicant.application.applicants.length,
            overlap: "",
            color: "warning darken-2 "
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("action", "screening-toolbar-application")
                }
              }
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v("mdi-clipboard-text-multiple")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-badge",
        {
          staticClass: "mr-4 hidden-sm-and-down",
          attrs: {
            content: _vm.currentScreen.screen.notifications.length,
            overlap: "",
            color: "info"
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("quicklink:click", {
                    id: "screening-notifications"
                  })
                }
              }
            },
            [_c("v-icon", { attrs: { large: "" } }, [_vm._v("mdi-bell")])],
            1
          )
        ],
        1
      ),
      _c("v-divider", {
        staticClass: "accent lighten-1 mx-4 hidden-sm-and-down",
        attrs: { vertical: "", dark: "", inset: "" }
      }),
      _vm._l(_vm.items, function(item, index) {
        return [
          item.type === "button"
            ? _c(
                "v-tooltip",
                {
                  key: index,
                  attrs: { top: "", color: "accent" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "mr-4 hidden-md-and-down",
                                    attrs: { icon: "", dark: "" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.$emit("action", item.event)
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { large: "" } }, [
                                  _vm._v(_vm._s(item.icon))
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [_c("span", [_vm._v(_vm._s(item.tooltip))])]
              )
            : _vm._e(),
          item.type === "divider"
            ? _c("v-divider", {
                key: "divider-" + index,
                staticClass: "accent lighten-1 mx-4 hidden-md-and-down",
                attrs: { vertical: "", dark: "", inset: "" }
              })
            : _vm._e()
        ]
      }),
      _c(
        "v-btn",
        {
          class: { "mr-4": _vm.$vuetify.breakpoint.mdAndUp },
          attrs: { icon: "", dark: "", small: _vm.$vuetify.mdAndDown }
        },
        [
          _c(
            "v-icon",
            {
              attrs: {
                large: _vm.$vuetify.mdAndUp,
                small: _vm.$vuetify.mdAndDown
              }
            },
            [_vm._v("mdi-dots-vertical")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }