<template>
    <div>
        <div v-if="initializing">
            <h3 class="primary--text mb-2">Loading order ...</h3>
            <v-progress-linear
                height="10"
                color="primary"
                rounded
                indeterminate
            ></v-progress-linear>
        </div>
        <div v-else>
            <!-- TODO: Add the application drop-down -->
            <psi-page-title
                :title="$_.get(currentScreen, 'applicant.name', '')"
                :icon="page.icon"
                :breadcrumbs="breadcrumbs"
                @breadcrumbs-item-selected="breadCrumbItemSelected"
            ></psi-page-title>
            <!-- Content always starts with a v-card  -->
            <v-card class="mt-4 px-3 py-5">
                <!-- TODO: generate alerts from backend , alert here if we have alerts -->
                <screening-process-alert
                    :alerts="$_.get(currentScreen, 'screen.alerts', [])"
                >
                </screening-process-alert>
                <!-- Toolbar is sticky on scroll, uses the vuex store to get current screen -->
                <!-- TODO: Retrieve the menu options from the toolbar -->
                <screening-process-toolbar
                    @quicklink:click="scrollTo($event)"
                    @action="handleToolbarAction($event)"
                ></screening-process-toolbar>
                <!-- Content, setup as a 3 col view but main content can span 2 cols -->
                <v-row>
                    <!-- Main Content -->
                    <v-col cols="12" lg="8" md="8" sm="12">
                        <v-row>
                            <template v-for="(group, index) in contentGroups">
                                <v-col
                                    :class="group.colsClass"
                                    :key="`main-${index}`"
                                >
                                    <template v-for="(item, i) in group.items">
                                        <component
                                            :is="item.component"
                                            v-bind="getItemProps(item)"
                                            :key="`item-${index}-${i}`"
                                            :id="item.id"
                                            :title="item.title"
                                            :headingColor="
                                                $_.get(
                                                    item,
                                                    'headingColor',
                                                    'primary'
                                                )
                                            "
                                            :icon="item.icon"
                                            :height="$_.get(item, 'height', '')"
                                            :maxHeight="item.maxHeight"
                                            :class="i > 0 ? 'mt-6' : ''"
                                        ></component>
                                    </template>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <!-- Sidebar Content -->
                    <v-col cols="12" lg="4" md="4" sm="12" class="mt-3">
                        <template v-for="(item, index) in sideBarItems">
                            <component
                                :is="item.component"
                                v-bind="getItemProps(item)"
                                :headingColor="
                                    $_.get(item, 'headingColor', 'primary')
                                "
                                class="mb-6"
                                :key="index"
                                :id="item.id"
                                :title="item.title"
                                :icon="item.icon"
                                :height="$_.get(item, 'height', '')"
                                :maxHeight="item.maxHeight"
                            ></component>
                        </template>
                    </v-col>
                </v-row>
            </v-card>
            <!-- Handle the toolbar action of creating a note -->
            <v-dialog v-model="noteDialog" max-width="800">
                <!-- Adding v-if will ensure the component is mounted each time we open the dialog and won't have to reset text area and validation -->
                <screening-note
                    v-if="noteDialog"
                    :order_id="currentScreen.order_id"
                    :addresses="
                        $_.get(currentScreen, 'applicant.addresses', [])
                    "
                    :employers="
                        $_.get(currentScreen, 'applicant.employers', [])
                    "
                    @close="noteDialog = false"
                >
                </screening-note>
            </v-dialog>

            <v-dialog v-model="applicationDialog" max-width="1280">
                <!-- Adding v-if will ensure the component is mounted each time we open the dialog and won't have to reset text area and validation -->
                <screening-application
                    v-if="applicationDialog"
                    :application_id="
                        $_.get(currentScreen, 'applicant.application.id', {})
                    "
                    @close="applicationDialog = false"
                >
                </screening-application>
            </v-dialog>

            <v-dialog
                v-model="phoneDialog"
                :max-width="phoneDialogFullscreen ? '' : '600'"
                :fullscreen="phoneDialogFullscreen"
                persistent
            >
                <phone-viewer
                    v-if="phoneDialog"
                    :contacts="currentScreen.contacts"
                    :applicant="currentScreen.applicant"
                    :order_id="currentScreen.order_id"
                    @show:info="showCallInfo"
                    @close="
                        phoneDialog = false;
                        phoneDialogFullscreen = false;
                    "
                ></phone-viewer>
            </v-dialog>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import ScreeningProcessToolbar from "@components/screen/components/ScreeningProcessToolbar";
import ScreeningProcessAlert from "@components/screen/components/ScreeningProcessAlert";
import ScreeningBlank from "@components/screen/components/ScreeningProcessBlank";
import ScreeningNotifications from "@components/screen/components/ScreeningProcessNotifications";
import ScreeningDetails from "@components/screen/components/ScreeningProcessDetails";
import ScreeningFlags from "@components/screen/components/ScreeningProcessFlags";
import ScreeningSummary from "@components/screen/components/ScreeningProcessSummary";
import ScreeningTasks from "@components/screen/components/ScreeningProcessTasks";
import ScreeningActivities from "@components/screen/components/ScreeningProcessActivities";
import ScreeningHousehold from "@components/screen/components/ScreeningProcessHouseholdSummary";
import ScreeningScorecard from "@components/screen/components/ScreeningProcessScorecard";
import ScreeningContacts from "@components/screen/components/ScreeningProcessContacts";
import ScreeningNote from "@components/screen/components/ScreeningProcessNote";
import ScreeningApplication from "@components/screen/components/ScreeningProcessApplication";
import ScreeningNotesListing from "@components/screen/components/ScreeningProcessNotesListing";
import ScreeningRentalHistory from "@components/screen/components/ScreeningProcessRentalHistory";
import ScreeningProcessApplicant from "@components/screen/components/ScreeningProcessApplicant";
import ScreeningInvoice from "@components/screen/components/ScreeningProcessInvoice";
import ScreeningEmploymentHistory from "@components/screen/components/ScreeningProcessEmploymentHistory";
import ScreeningProcessCredit from "@components/screen/components/ScreeningProcessCredit";
import ScreeningProcessCrime from "@components/screen/components/ScreeningProcessCrime";
import PhoneViewer from "@components/phone/components/PhoneViewer";

export default {
    name: "screening-process",
    components: {
        ScreeningProcessToolbar,
        ScreeningBlank,
        ScreeningProcessAlert,
        ScreeningNotifications,
        ScreeningDetails,
        ScreeningFlags,
        ScreeningSummary,
        ScreeningTasks,
        ScreeningActivities,
        ScreeningHousehold,
        ScreeningScorecard,
        ScreeningContacts,
        ScreeningNote,
        ScreeningApplication,
        ScreeningNotesListing,
        ScreeningRentalHistory,
        ScreeningProcessApplicant,
        ScreeningInvoice,
        ScreeningEmploymentHistory,
        ScreeningProcessCredit,
        ScreeningProcessCrime,
        PhoneViewer,
    },
    data() {
        return {
            sideBarItems: [],
            contentGroups: [],
            selectedItemId: this.$config("screen.selectedNavItemId"),
            noteDialog: false,
            applicationDialog: false,
            phoneDialog: false,
            phoneDialogFullscreen: false,
            page: {
                title: "Florence Jacobson",
                icon: "mdi-clipboard-account",
            },
            breadcrumbs: [
                {
                    text: "Order History",
                    disabled: false,
                    to: { name: "Order History" },
                },
                {
                    options: {
                        text: "text",
                        value: "value",
                        width: 250,
                        selected: null,
                        items: [],
                    },
                },
                {
                    text: "Screening Processing",
                    disabled: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Screens", [
            "currentScreen",
            "currentApplication",
            "loading",
        ]),
        initializing() {
            return (
                this.loading ||
                Object.keys(this.currentScreen).length === 0 ||
                Object.keys(this.currentApplication).length === 0
            );
        },
    },
    methods: {
        ...mapActions("Screens", ["getCurrentScreen"]),
        showCallInfo(show) {
            this.phoneDialogFullscreen = show;
        },
        scrollTo(event) {
            this.$vuetify.goTo(`#${event.id}`, {
                easing: "easeInOutCubic",
                offset: 100,
                duration: 450,
            });
        },
        getItemProps({ id, title, icon, params }) {
            let props = {};
            for (const key in params) {
                props[key] = this.$_.get(this.currentScreen, params[key]);
            }
            return {
                ...props,
                id,
                title,
                icon,
            };
        },
        getSidebarItems() {
            this.sideBarItems = this.$config("screen.navItems").filter(
                (item) =>
                    item.layout === "sidebar" &&
                    item.visible &&
                    // Use a selected Item Id to exclude all other nav items / tiles, convenient for debug and development
                    (item.id === this.selectedItemId || !this.selectedItemId)
            );
        },
        getContentItems() {
            this.$config("screen.navItems")
                .filter(
                    (item) =>
                        item.layout === "main" &&
                        item.visible &&
                        // Use a selected Item Id to exclude all other nav items / tiles, convenient for debug and development
                        (item.id === this.selectedItemId ||
                            !this.selectedItemId)
                )
                .forEach((item) => {
                    // are we grouping this item
                    if (Object.keys(item).includes("group")) {
                        let contentGroup = this.contentGroups.find(
                            (group) => group.code === item.group
                        );
                        if (contentGroup) {
                            contentGroup.items.push(item);
                        } else {
                            this.contentGroups.push({
                                code: item.group,
                                colsClass: item.colsClass,
                                items: [item],
                            });
                        }
                    } else {
                        this.contentGroups.push({
                            code: "",
                            colsClass: item.colsClass,
                            items: [item],
                        });
                    }
                });
        },
        handleToolbarAction(action) {
            switch (action) {
                case "screening-toolbar-add-note":
                    this.noteDialog = true;
                    break;
                case "screening-toolbar-application":
                    this.applicationDialog = true;
                    break;
                case "screening-toolbar-call":
                    this.phoneDialog = true;
                    break;
            }
        },
        applicationBreadCrumbItems() {
            const currentApplicantId = this.currentScreen.applicant.id;

            const items = this.currentApplication.applicants.map((item) => {
                return {
                    value: item.id,
                    text: `${item.type} - ${item.name}`,
                    disabled: item.id === currentApplicantId,
                };
            });
            return items;
        },
        breadCrumbItemSelected(item) {
            const applicant = this.currentApplication.applicants.find((val) => {
                return val.id == item.value;
            });
            this.$router.push({
                name: "Application Process",
                params: { id: applicant.screen.order_id },
            });
        },
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(to) {
                this.getCurrentScreen(to.params.id);
            },
        },
        currentApplication: {
            immediate: true,
            handler(val) {
                if (this.$_.has(val, "applicants")) {
                    this.breadcrumbs[1].options.selected = this.currentScreen.applicant.id;
                    this.breadcrumbs[1].options.items = this.applicationBreadCrumbItems();
                }
            },
        },
    },
    created() {
        this.getSidebarItems();
        this.getContentItems();
        //      this.getCurrentScreen(this.$route.params.id);
    },
};
</script>

<style scoped>
.fixed-toolbar {
    position: -webkit-sticky;
    position: sticky;
    top: 4.1rem;
    z-index: 2;
}
</style>