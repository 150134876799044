var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      attrs: {
        icon: "mdi-account-alert",
        border: "top",
        color: "secondary",
        dismissible: "",
        prominent: "",
        dark: ""
      }
    },
    [
      _vm._l(_vm.alerts, function(ref, index) {
        var message = ref.message
        var type = ref.type
        return [
          _c(
            "v-chip",
            {
              key: index,
              staticClass: "mr-2",
              attrs: {
                small: "",
                color:
                  type === "Error" ? "error darken-2" : "secondary darken-2"
              }
            },
            [_vm._v(_vm._s(message))]
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }