<template>
    <v-card :id="id" color="grey lighten-2">
        <psi-card-heading
            :headingTitle="title"
            :icon="icon"
            :heading-color="headingColor"
        ></psi-card-heading>
        <v-card-text class="py-2">
            <v-tabs icons-and-text v-model="tab" color="primary">
                <template v-for="(employer, index) in employers">
                    <v-tab :key="`tab-${index}`">
                        {{ employer.type }} Employment
                        <v-badge
                            :icon="
                                employer.is_verified ? 'mdi-check' : 'mdi-close'
                            "
                            overlap
                            bordered
                            :color="
                                employer.is_verified
                                    ? 'accent darken-1'
                                    : 'warning darken-2'
                            "
                        >
                            <v-icon large class="accent--text text--darken-1">{{
                                employer.type === "Current"
                                    ? "mdi-calendar-cursor"
                                    : "mdi-calendar-clock"
                            }}</v-icon>
                        </v-badge>
                    </v-tab>
                </template>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <template v-for="(employer, index) in employers">
                    <v-tab-item :key="index">
                        <employment-employer
                            :employer="employer"
                            :order_id="order_id"
                        ></employment-employer>
                    </v-tab-item>
                </template>
            </v-tabs-items>
            <v-btn
                absolute
                top
                right
                color="accent darken-1"
                class="mt-15 mr-2"
                large
                @click.stop="dialog = true"
            >
                <v-icon large>mdi-briefcase-account</v-icon>
                Add Employer</v-btn
            >
        </v-card-text>
        <v-dialog v-model="dialog" max-width="800">
            <psi-dialog
                heading-color="accent darken-1"
                title="Add Employer"
                scrollable
                icon="mdi-briefcase-account"
                @close="dialog = false"
                v-if="dialog"
                hide-actions
            >
                <employment-employer-edit
                    @close="dialog = false"
                ></employment-employer-edit>
            </psi-dialog>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-employment-history",
    components: {
        EmploymentEmployer: () => import("./EmploymentEmployer"),
        EmploymentEmployerEdit: () =>
            import("./Employer/EmploymentEmployerEdit"),
    },
    props: {
        order_id: {
            type: [String, Number],
            required: true,
        },
        employers: {
            type: Array,
            required: true,
        },
        id: {
            type: String,
            required: false,
            default: "screening-employment-history",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        title: {
            type: String,
            required: false,
            default: "Blank",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-card-text",
        },
    },
    data() {
        return {
            tab: null,
            dialog: false,
        };
    },
    computed: {},
    methods: {},
};
</script>

<style scoped>
</style>