var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.applicantData
    ? _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _vm._l(_vm.fields, function(ref, index) {
                var id = ref.id
                var name = ref.name
                var label = ref.label
                var rules = ref.rules
                var mask = ref.mask
                var placeholder = ref.placeholder
                var required = ref.required
                var icon = ref.icon
                var outerIcon = ref.outerIcon
                return [
                  _c(
                    "psi-form-element",
                    { key: index, attrs: { label: label, required: required } },
                    [
                      _c("psi-form-text-field", {
                        attrs: {
                          name: name,
                          icon: icon,
                          value: _vm.applicantData[name],
                          rules: rules,
                          mask: mask,
                          "outer-icon": outerIcon,
                          placeholder: placeholder,
                          color: "accent darken-2"
                        },
                        on: {
                          "click:append-outer": function($event) {
                            return _vm.handleOuterIconClick(id)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              })
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "mr-2" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "accent darken-2" } },
                [
                  _c("v-icon", { staticClass: "mr-1" }, [
                    _vm._v("mdi-account")
                  ]),
                  _vm._v(" Save ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }