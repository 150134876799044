<template>
    <v-card :id="id">
        <psi-card-heading
            :heading-color="headingColor"
            :headingTitle="title"
            :icon="icon"
            :indicator="tasks.length"
        ></psi-card-heading>
        <v-card-text>
            <h4 class="font-weight-regular mt-2 mb-1 primary--text">
                Progress {{ progress }}%
            </h4>
            <v-progress-linear
                :value="progress"
                height="8"
                rounded
            ></v-progress-linear>
            <!-- <perfect-scrollbar :style="cssVars">
                <div :style="`height:${height}px !important`"> -->
            <v-list>
                <template v-for="(task, index) in tasks">
                    <v-list-item :key="index">
                        <v-list-item-icon>
                            <v-icon
                                :color="
                                    task.complete
                                        ? 'primary darken-2'
                                        : 'grey lighten-2'
                                "
                                >mdi-check-circle</v-icon
                            >
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                class="primary--text text--darken-2"
                                >{{ task.label }}</v-list-item-title
                            >
                            <v-list-item-subtitle
                                class="primary--text"
                                v-if="task.complete"
                            >
                                by {{ task.user.name }}
                                {{ task.completed_timespan }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <psi-popover :title="task.label">
                                {{ task.hint }}
                            </psi-popover>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="`divider-${index}`"></v-divider>
                </template>
            </v-list>
            <!-- </div>
            </perfect-scrollbar> -->
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "screening-process-tasks",
    components: {},
    props: {
        id: {
            type: String,
            required: false,
            default: "screening-tasks",
        },
        title: {
            type: String,
            required: false,
            default: "Tasks",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-format-list-checks",
        },
        height: {
            type: [String, Number],
            required: false,
            default: 1200,
        },
        maxHeight: {
            type: Number,
            required: false,
            default: 1200,
        },
        progress: {
            type: Number,
            required: true,
        },
        tasks: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        cssVars() {
            return {
                "--ps-height": this.maxHeight + "px",
            };
        },
    },
    methods: {},
};
</script>

<style scoped>
.ps {
    height: 1200px;
}
</style>