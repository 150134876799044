var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h3",
        { staticClass: "my-4 d-flex font-weight-regular" },
        [
          _vm._v(" Files "),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { small: "", color: "accent darken-2", dark: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.dialog = true
                }
              }
            },
            [
              _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                _vm._v("mdi-file-upload")
              ]),
              _vm._v("Add File ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _vm._l(_vm.applicantFiles, function(file, index) {
            return [
              _c(
                "v-list-item",
                { key: index },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "align-items-center" },
                    [
                      _c("v-icon", { attrs: { color: "accent darken-2" } }, [
                        _vm._v("mdi-file")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-title",
                    { staticClass: "text-subtitle-2 d-flex align-center" },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.$_.get(file, "url", ""),
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(file.type))]
                      ),
                      _c("v-spacer"),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", color: "accent darken-2" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mx-1",
                                                attrs: {
                                                  icon: "",
                                                  color: "accent darken-2"
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-open-in-app")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v("View " + _vm._s(file.type) + " file")
                              ])
                            ]
                          ),
                          _c("v-divider", { attrs: { vertical: "" } }),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", color: "accent darken-2" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mx-1",
                                                attrs: {
                                                  icon: "",
                                                  color: "accent darken-2"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    _vm.dialog = true
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-file-replace")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v("Replace " + _vm._s(file.type) + " file")
                              ])
                            ]
                          ),
                          _c("v-divider", { attrs: { vertical: "" } }),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", color: "accent darken-2" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mx-1",
                                                attrs: {
                                                  icon: "",
                                                  color: "accent darken-2"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.removeFile(
                                                      file.id
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-file-remove")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v("Remove " + _vm._s(file.type) + " file")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider", { key: "divider-" + index })
            ]
          })
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm.dialog
            ? _c(
                "psi-dialog",
                {
                  attrs: {
                    title: "Add File",
                    icon: "mdi-file-upload",
                    headingColor: "accent darken-2",
                    "hide-actions": ""
                  },
                  on: {
                    close: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [
                  _c("psi-form-file-upload", {
                    attrs: { name: "Applicant File" }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }